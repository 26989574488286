.dnd-img
  filter grayscale(1)
  tr 0.5s
  w 24px 
  opacity: 0.5
  h auto
  d block
  ma 0 auto
.active-img 
  tr 0.5s
  opacity: 1
  filter grayscale(0)


.popup-without-bg
  // br: 1px solid $c.grey
  overflow: visible !important
  bg white
  box-shadow: 5px 5px 10px rgba(0,0,0,.5)
  right: 0;
  top: 60px;
  position: absolute;
  z-index: 1;


.order
  d flex 
  jc space-between
  // mt 20px
  &__row 
    jc: space-between
    ai: flex-end 
    d: flex
  &__employees-market 
    w 29%
  &__wrap 
    d flex 
    ai center 
    jc flex-start 
    flex-wrap wrap
    // br: 1px solid $c.relief--dark
  .tabs 
    pa 0   
    ma 0 
    br none
  &__br-tab
    br: 1px solid $c.relief--dark  
    // br-top none
    pa 5px
    d flex
    f-dir column
    // &--scroll 
    h 1000px 
    +media(from('md'))
      overflow-y auto 
      h calc(100vh - 200px)
    +media(from('mac'))
      h auto
    &--row-xl
      br: none
      +media(from('xl'))
        f-dir: row
        flex-wrap: wrap
        jc: space-between
        ai: flex-start
      
  .employees-modal
    w 70%
    &__title 
      mb 0 
      pb 9px
      ft uppercase
      fs 15px 
      fw 700
      color: $c.asphalt
    &__wrap 
      d flex
      ai center 
      jc flex-start
      flex-wrap wrap
    &__col
      br: 1px solid $c.relief--dark
      tr 1s
      mb 10px
      +media(from('xl'))
        w: 49%
      &.is-active 
        tr 1s
        box-shadow 5px 5px 20px rgba(0,0,0,0.5)
    &__action
      w: 100%    
      d: flex 
      ai: center 
      jc: space-between
    &__showups    
      br none
      pa 5px
    .list-table
      w auto  
      // ml 20px 
      &__cell 
        pa 5px 
    .field-wrapper  
      mr 20px
.employees
  d flex 
  ai center 
  jc flex-start 
  flex-wrap wrap
  mb 0px
  &__wrap
    d flex 
    ai center 
    jc flex-start 
    flex-wrap wrap
  &__bundle 
    pa 5px 
    mr 5px
    d flex 
    ai flex-start
    br: 1px solid $c.blue-trans
.tabs-market
  d flex
  &__tab
    fs 15px 
    pa 5px
    d block
    br-top-right-radius 3px
    br-top-left-radius 3px
    list-style none
    clear fix
    ma 0
    ft uppercase
    pa 10px
    d flex
    color: $c.white
    fd none
    bg: $c.asphalt
    br: 1px solid $c.relief--dark

   
    &.is-active
      fw 700
      color: $c.asphalt
      bg #fff
  
      br-bottom-color #fff

    &.dis
      pointer-events: none
      color grey  
.tabs-childs
  &__tab 
    fs 15px 
    pa 5px
    ft uppercase
    &:after 
      content '|'
      color: $c.asphalt    
      pl 10px 
    &:last-child::after 
      content: ''
    &.is-active
      fw 700

.shadow-card
  pa 10px 
  br: 1px dashed $c.relief--dark
  ma 5px 
  cursor move
  pos relative 
  &__name 
    fw 700
  &__label 
    pos absolute 
    top -10px
    fw 400 
    left 5px 
    bg white 
    pa 0 3px 
    ma 0
  & .icon 
    pos absolute 
    bg white 
    top -8px
    right -8px
  &.added 
    br: 1px solid $c.asphalt
  &__status 
    w 10px 
    h 10px 
    d block 
    br-radius 100%
    pos absolute 
    bottom -5px
    left 50% 
    transform translateX(-50%)
    &.val-1
      bg green
    &.val-2
      bg red
    &.val-3
      bg orange  
  &__flex 
    d flex 
    jc center
    ai center     
  .field-wrapper
    mr 0 !important  
.drop.allowed 
  background: linear-gradient(to bottom, $c.lavender 50%, #1B676B 50%);
  background-size: auto 2px;
  outline: 2px dashed $c.lavender;
  box-shadow: 0 0 0 2px #1B676B;
  border-color: blue;
  animation: 1s animateBorderThree ease infinite;
  tr 0.5s
  tr 0.5s


@keyframes animateBorderThree
  to 
    outline-color  #1B676B
    box-shadow: 0 0 0 4px $c.lavender


// .view 
//   overflow-x auto
.full-screen
  min-w 1200px



.sidebar 
  &__link 
    pa 6px 
    +media(from('mac'))
      pa 16px

.digital-business-card
  d: flex
  ai: center
  jc: center
  flex-wrap: wrap
  +media(from('xl'))
    jc: flex-start
  &__
    &text
      w: 100%
      text-align center
      +media(from('lg'))
        text-align left
    &qr-block
      mr: 20px
      ml: 20px
      +media(from('xl'))
        ml: 0
    &company-logo
      d: flex
      margin-top: 10px
      jc: center
      +media(from('xl'))
        jc: flex-start
    &title
      ta: center
      +media(from('xl'))
        ta: start
    &img
      max-h: 78px

.button--small
  height: 34px