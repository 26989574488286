o-navigation-active()
  &.is-active
  &.router-link-active
  &.router-link-exact-active
    {block}


.o-navigation
  d flex
  +media(to('sm'))
    flex-wrap wrap 
  &__
    &item
      d block
      pa 2sp 3sp
      fs 12px
      fh 1.3 * @fs
      ft uppercase
      fd none
      color inherit

      // &:not(:last-child)
      //   mr 1sp
      //   mr--md 2sp

      &:hover
        bg: $c.relief

      +o-navigation-active()
        // @extend .button--t-primary
        bg: $c.relief--dark

  &--
    &level-1
    &level-2
    &level-3
      border-bottom: 1px solid $c.relief--dark

    &level-1
      & ^[0]__
        &item
          @extend .button--s-big
          ph 3sp

    &level-2
      & ^[0]__
        &item
          @extend .button--s-md
          ph 3sp
          fs 14px

    &nested
      mt 0.5sp
      mt--md 0.5sp

    // &primary
    //   bg: $c.asphalt
    //   color #fff
    //   border-bottom-color: $c.asphalt--dark

    //   & ^[0]__
    //     &item
    //       +o-navigation-active()
    //         bg: $c.asphalt--dark

    &full-content
      ph 2sp
      ph--md 4sp
      mh -2sp
      mh--md -4sp

    &full-content&tabs
      ph 0
      ph--md 0

    &tabs
      & ^[0]__
        &item
          flex-grow 1


.discount-toggler
  d flex 
  ai center 
  jc center 
  pos absolute 
  right 8px 
  top 23px
  &__btn 
    w 34px 
    fs 15px
    h 34px 
    bg: #c8d3dc
    color: $c.asphalt 
    d flex 
    ai center 
    fd none 
    fw 700
    jc center 
    tr 0.5s 
    &.is-active
      color: $c.white 
      bg: $c.blue
      tr 0.5s 

    +media(from('xl')) 
      &:hover 
        color: $c.white 
        bg: $c.blue
        tr 0.5s 
    