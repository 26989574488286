@b list
  &
    @extends $view

  @e
    &row
      d flex
      ai center
    &actions
    &pagination
      min-w 30%

    &pagination
      ta right
      flex-grow 1

    &search-id
      ph 2sp
      flex-grow 1
      max-width 100px

    &search
      ph 2sp
      flex-grow 1

    &title
      pr 2sp

    &filter
      d flex
      mh -2sp

      @media (max-width: 960px)
        d block

    &filter-field
      flex-grow 1
      flex-basis 1px
      ph 2sp

      @m
        &s-
          for $i in 1..15
            &{$i}
              flex-grow $i
    &section
      @m
        &flex-list
          d flex
          jc space-between
          ai center
          flex-wrap wrap
        &scroll
          +media(to('lg'))
            overflow-x scroll
    &header
      &--flex
        justify-content: space-between
        align-items: center
  @media (max-width: 960px)
    &__
      &header
        // d block
        flex-wrap wrap

      &title
      &search
      &search-id
      &views
        d block

      &search
      &search-id
        pr 0

      &search
      &search-id
        max-width: none
        margin-bottom: 2sp

      &views
        pa 0
        d flex
        w 100%
        mt 2sp

        & .button
          flex-grow 1
          mh 2sp
          ta center

          &:first-child
            ml 0
          &:last-child
            mr 0
