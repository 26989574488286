@b detail
  pos relative

  &
    @extends $view


.assets
  d flex
  ai center 
  jc flex-start 
  ma 1px
  &__link
    mr 10px 
.asset-wrap 
  d flex
  f-dir column 
      

.smooth-dnd-container.vertical > .smooth-dnd-draggable-wrapper
  overflow: initial !important


.company-logo
  w: 200px
  mr: 20px