.container
  min-w 1280px
  min-h 100%

  @e
    &inner
      d flex
      min-h 100%

    &sidebar
      wf 40px
      wf--md 50px
      // wf--lg 70px
      wf--xl 70px

      // bg: $c.asphalt
      color #fff

    &content
      pl--md 10px
      flex-grow 1
      max-width calc(100% - 40px)
      +media(from('md'))
        max-width calc(100% - 50px)
      +media(from('xl'))
        max-width calc(100% - 70px)

  @m
    &responsive
      min-w 0
