@b pagination
  list-style none
  d block
  pa 0
  ma 0

  @e
    &item
      d inline-block

    &link
      @extends $button
      @extends .button--t-default

      fd none
      color: $c.asphalt

      @m
        &prev
        &next
          bg transparent
          fd none

      @s
        &active
          @extends .button.is-active

          color #fff
