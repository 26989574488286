.object-payment-loader
  display: flex
  align-items: center
  justify-content: center
  position fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  z-index: 5
  &__
    &text
      position relative
      z-index: 11
      padding-bottom: 100px
      fw: 700
      fs: 16px
#vue-inline-stripe
  max-width: 300px
  pa: 0 0 20px
#vue-inline-top-stripe
  pa: 20px 0 
.or-divider
  d: flex
  ai: center
  jc: center
  mt: 20px
  &:before, &:after
    content: ''
    flex-grow: 1
    height: 1px
    background: #e6e6e6
    margin: 0 10px
    display block
    width 50%
    pos: absolute
  &:before
    left: 0 
  &:after
    right: 0
  &__text
    background: white
    pa: 5px 10px
    d: block
    position relative
    z-index 2
    color: black
    fw 700


.visually-hidden
  position: absolute

  width: 1px
  height: 1px
  margin: -1px
  padding: 0
  border: 0

  white-space: nowrap

  clip-path: inset(100%)
  clip: rect(0 0 0 0)
  overflow: hidden

.order-asset-preview 
  display: flex
  align-items: center
  mb: 10px
  &__
    &link
      mr: 10px
      display inline-block
    &loaded
      color: $c.green



.orders-count,
.quotes-count
  w: auto
  h: 16px
  d: inline-flex
  jc: center 
  ai: center
  fw: 700
  color: white
  min-w: 16px
  br-radius: 25px

.orders-count
  bg: #3d8c7f
  mr: 5px

.quotes-count
  bg: #7a74b2
  ml: 5px

.chat-list-wrap
  +media(from('lg'))
    display: flex
    .chat
      width 100% !important
  &__
    &section
      +media(from('lg'))
        width 100% !important