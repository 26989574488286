@b list-actions
  d flex
  align-items center

  @e
    &all
    &select
      ph 2sp

    &all
      wf 110px
      fw bold
      fs 1.2em

    &select
      flex-grow 1
