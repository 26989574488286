$field-checkbox-size = 20px
$field-border-radius = 3px

// ---

field-focus()
  outline none
  box-shadow: 0 0 0 2px $c.highlight


// ---

$field-checkbox-container
  h $field-checkbox-size
  fh $field-checkbox-size

  [type="radio"]
  [type="checkbox"]
    position absolute
    opacity 0
    z-index -100


$field-checkbox
  pos relative
  d inline-block
  va middle
  h $field-checkbox-size
  fh $field-checkbox-size

  icon check after

  &:before
  &:after
    d block
    w $field-checkbox-size
    h $field-checkbox-size
    fh $field-checkbox-size
    va middle

  &:before
    br-radius $border-radius
    bg: $c.gray
    content ''

  &:after
    pos absolute
    top 0
    left 0
    fs 3ty
    ta center
    d none

  input:checked + &
    &:after
      d block

  input:focus + &
    &:before
      field-focus()

  input:readonly + &
  input[readonly] + &
  input:disabled + &
  input[disabled] + &
    user-select none

    &:before
      br: 1px solid $c.relief--dark
      bg transparent


@b field-checkbox
  &
    @extends $field-checkbox-container

  va text-bottom

  @e
    &element
      @extends $field-checkbox

  @m
    &cross
      @bn
        @e
          &element
            icon times after

            &:after
              d none

              fh 1.3

    &cellfiller
      d block
      h 100%
      w 100%

      @bn
        @e
          &element
            d block

            &:before
            &:after
              w 100%
              h 100%
              // bg transparent

            &:before
              d block
              visibility hidden

            &:after
              ma -2sp
              w calc(100% + 2sp * 2)
              h calc(100% + 2sp * 2)

              fs 4sp
              fh 1.2em

              bg: $c.red
              color #fff


.field-radio
  @extends $field-checkbox-container

  @e
    &element
      @extends $field-checkbox

      &:before
        br-radius 999px


@b field-radiobutton
  &
    @extends $field-checkbox-container

  @e
    &element
      @extends $field-checkbox

      w auto
      bg: $c.gray
      ph 2sp

      br-radius $border-radius

      &:before
        d inline-block
        bg none
        br-radius 999px

      input:focus + &
        &:before
          box-shadow 0 0 0 0 transparent

      input:checked + &
        bg: $c.asphalt
        color #fff

  @m
    &yes
      input:checked +
        @bn
          @e
            &element
              bg: $c.green
              color #fff

    &no
      input:checked +
        @bn
          @e
            &element
              bg: $c.red
              color #fff


// ---

$field-input
  pa 2sp 2sp
  fs 12px
  fh 1.3 * @fs
  font-family inherit
  border 1px solid transparent
  border-radius $field-border-radius

  &:disabled,
  &[disabled]
    user-select none
    br: 1px solid $c.relief--dark
    bg transparent

  .is-readonly &[readonly]
    pointer-events none
    br: 1px solid $c.relief--dark
    bg transparent

  .is-readonly &:readonly
    pointer-events none
    br: 1px solid $c.relief--dark
    bg transparent

field-input--s-()
  &xs
    pa 1.2sp 2sp

  &sm
    pa 1.6sp 2sp

  &big
    pa 2.6sp 3sp
    fs 16px
    fh 1.3 * @fs
    fw normal

.field-input
  @extends $field-input

  color: $c.asphalt

  bg: $c.relief--dark
  width 100%

  &:focus
    field-focus()

  @m
    &white
      bg #fff

    &s-
      field-input--s-()

select.field-input[disabled]
  appearance inherit

select.field-input:disabled
  appearance inherit

select.field-input
[type="date"].field-input
[type="datetime"].field-input
[type="time"].field-input
  appearance none
  height 34px


.field-textarea
  @extends .field-input

// ---

.field-canvas
  @extends $field-input

  pa 0

  bg: $c.relief--dark
  width auto
  display inline-block
  max-width 100%

  canvas, .canvas-container
    pos relative
    max-width 100%

  .canvas-container
    height 0 !important
    pt (320/480) * 100%

  canvas
    pos absolute
    top 0
    left 0
    max-width 100%
    max-height 100%
// ---

.field-select
  @extends .field-input

  bg: $c.relief--dark
  appearance none


// ---
postfix(w = 3em)
  pos absolute
  right 0
  top 0
  bottom 0
  w w
  d flex
  align-items center
  justify-content center

  ta center

  bg: blend($c.relief--dark)
  color: $c.asphalt
  border-top-right-radius $field-border-radius
  border-bottom-right-radius $field-border-radius


field-wrapper-postfix(w = 3em)
  &:after
    postfix(w = 3em)

    pointer-events none


@b field-wrapper
  pos relative
  $w = 3em

  @e
    &description
    &error
      fs 1ty

    &description
      fw 200
      color: $c.asphalt--faded

      ul
        d block
        ma 0
        pa 0
        ta left
        list-style none

    &error
      color: $c.red
    &call-btn
      pos absolute
      fd: none
      right 0
      bottom 0
      h: 34px
      w 3em
      d flex
      align-items center
      justify-content center

      ta center

      bg: blend($c.relief--dark)
      color: $c.asphalt
      border-top-right-radius $field-border-radius
      border-bottom-right-radius $field-border-radius
      fw bold
      color #fff
      br-left: 1px solid $c.relief--dark
      bg-color: $c.blue

  @m
    field-input--s-()

    &s-
      &big
        pa 0

    &select
      icon('trigon-down', 'after')

    &date-time
      icon('calendar', 'after')
      &:after
        fs 4ty
        w 2em !important


    &search
      &:after
        d none !important

      @bn
        @e
          &postfix
            border 0

            postfix()
            icon('search', 'after')
            fs 4ty

    &date-time
    &search
    &select
    &pay
    &phone
      & .field-input
        pr $w

      field-wrapper-postfix()
      &:after
        br-left: 1px solid $c.relief--dark

      @bn
        @e
          &postfix
            br-left: 1px solid $c.relief--dark

    &pay
      &:after
        content '$'
        fw bold
        color #fff
        bg-color: $c.green
    &phone

      & .field-input
        pr $w
    &flex
      d flex
      mh -1sp

      & > *
        d block
        flex-grow 1
        // ph 1sp

    &file
      overflow hidden
      pos relative
      ta center

      & input
        pos absolute
        w 0
        h 0
        right 150%
        bottom 150%

    &canvas
      d block
      ta center
      max-w 100%

    &avatar
      & .user-bar
        ta center

        @e
          &avatar
            w 320px
            h @w
            margin auto
            mb 2sp

            & img
              w @w
              h @h
              max-h @h

    &narrow
      .field-input
        pr 1.7em

      &:after
        w 1.7em
  & .multiselect
    &__tags 
      h auto

// ---

.field-label
  @m
    &block
      d block

      fs 1ty
      fw normal
      mb 1sp
      ft uppercase

    &spaced
      @extends $field-input
    &inline
      @extends $field-input

      d inline-block
      ta center
    &appearance-
      &none
        ft: none
      &center
        ta: center


// ---

.field-wrapper--tag-check
  d block
  clear fix
  pt unquote('calc((%s - %s) / 2)' % (3sp 2sp))

  > *
    float left
    mr 8px
.field-wrapper--vs-button
  d: flex

$field-tag-check
  @extends $field-input

  pos relative
  d inline-block
  pa 1sp 2sp
  va middle
  fs 12px

  br: 1px solid $c.relief--dark
  br-radius $field-border-radius
  bg #fff

  $w = 5px
  &:after
  &:before
    d block
    pos absolute
    top 50%
    left 100%
    ml 0

    content ''

  &:after
    mt 0 - $w
    triangle $w right #fff

  &:before
    $bw = 1px
    mt 0 - $w - $bw
    triangle: $w + $bw right $c.relief--dark

.field-tag-check
  @extends $field-checkbox-container

  va text-bottom
  h auto
  fh inherit

  @e
    &element
      @extends $field-tag-check

      input:checked + &
        br-color: $c.blue
        color: $c.blue

        &:before
          br-left-color: $c.blue

      input:focus + &
        field-focus()



// ---

$button
  @extends $field-input

  fs 1ty
  ft uppercase
  fw bold
  fd none
  fh 1.3em
  d inline-block

  bg transparent
  color inherit
  cursor pointer

  &:focus
    field-focus()

$button--xs
  pa 0.8sp 2sp
  fs 10px
  fh 1.3 * @fs
  fw normal

$button--sm
  pa 1sp 2sp
  fs 11px
  fh 1.3 * @fs
  fw normal

$button--md
  pa 2sp 3sp
  fs 16px
  fh 1.3 * @fs
  fw normal

button--s-()
  field-input--s-()

  &xs
    @extend $button--xs

  &sm
    @extend $button--sm

  &md
    @extend $button--md

  &big
    ph 4sp

.button
  & input[type='file']
    d none
  @extends $button

  $colors = ( \
    ('red' $c.red) \
    ('gray' $c.gray) \
    ('blue' $c.blue) \
    ('green' $c.green) \
  )

  @m
    &t- // Type of the button
      &default
        bg: $c.relief--dark

      &secondary
        bg: $c.asphalt
        color: #fff
        &:hover 
          bg: $c.asphalt--dark
      &primary
        bg: $c.blue
        color: #fff

      &full
        width 100%
        ta center

        @m
          &md
            @media (max-width: 768px)
              width 100%
              ta center

      &clean
        pa 0
      &flex
        d: flex
        ai center
        jc: center

      &nowrap
        white-space nowrap

    &middle
      vertical-align middle

    &c-
      for $c in $colors
        &{$c[0]}
          bg-color $c[1]
          color #fff

    &s-
      button--s-()

  @s
    &active
      bg: $c.blue
    color #fff

  & span
    d inline-block
    va middle

    &
    &:after
    &:before
      fh .8em
      h .8em
.mr-10
  mr 10px

.multiselect
  $h = 34px

  min-h 0 !important

  @e
    &select
      min-h $h !important
      // h $h
      h $h !important

    &tags
      @extends $field-input

      color: $c.asphalt !important

      bg: $c.relief--dark !important
      width 100% !important
      pa 1.5sp !important
      // h $h !important
      min-height $h !important

      &:focus
        field-focus()

    &input
    &single
      color inherit !important
      bg transparent !important
      fs inherit !important
      fh inherit !important
      font-family inherit !important
      mb 0 !important
  @m
    &disabled
      bg transparent !important
      br 1px solid rgba(200,211,220,0.4) !important
      br-radius 5px
      .multiselect
        &__tags, &__select 
          bg transparent !important






.flatpicker--disabled
  pointer-events: none
  bg transparent
  br 1px solid rgba(200,211,220,0.4) !important
input::disabled
  bg transparent !important
  br 1px solid rgba(200,211,220,0.4) !important


.medium-editor-element
  min-h 8ty

  h1,h2,h3,h4,h5,h6
  p
  blockquote
    &:first-child
      mt 0

    &:last-child
      mb 0

.horrable-labels
  .field-label
    fs: 20px
    fw: 700
.uppercase
  ft: uppercase
  fs: 18px 
  fw bold
  d: block
  pa: 0
  ma: 0
  ta: center
  &--
    &thin
      fw: 500
      fs: 16px
      // ft: initial
+media(to('lg'))
  .half-width-field 
    w: 40% !important
    min-w: 40% !important
  .half-half-width-field 
    w: 30% !important
    min-w: 30% !important
  .half-full-width-field 
    w: calc(64% - 4em) !important
    min-w: calc(64% - 4em) !important
    mb: 0.5rem
    .field-input
      h: 34px
  .short-width-field 
    w: 5% !important
    min-w: 5% !important
    d: flex 
    ai: center
    jc: center

.half-full-width-field 
  mb: 0.5rem

.qr-code-wrap
  mt: 20px
  &__
    &content
      d: flex
      ai: center
      jc: center
.canvas-code
  mt: 10px

.table-check-status
  &__img 
    w 30px 
    h 30px 
    d block 
  &__icon
    fw 700
    fs 20px
    &--red 
      color: $c.red 
    &--green
      color: $c.green


.duration-select
  fs: 16px
  pa: 4px

.duration-option
  pa: 4px

.bill-info
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  +media(to('lg'))
    w: 40%
  &__
    &label
      font-size: 0.75rem;
      font-weight: normal;
      display: block;
      margin-bottom: 0.25rem;
      text-transform: uppercase
      font-size: 20px;
      font-weight: 700;
      ta center
    &value
      @extend .uppercase
      color: $c.blue
      // text-align: center; 