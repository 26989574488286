.multiselect
  font-family 'Titillium Web', sans-serif
  pa 0
  border 0px solid transparent
  cursor pointer
  box-sizing border-box
  &__single,
  &__tags
    bg transparent 
    pa 0
    ma 0
    color: $c.blue--verylight
    fs 13px
    h 34px
  &__tags
    br none
  &__placeholder 
    pt 6px
  &__single
    w 100%
    d flex
    ai center
    pl 3px
    h auto
    fw 500
    pt 5px
    // mt 7px
    color: $c.blue--dark
    fs 13px !important
    display: inline-block;
    width: calc(100% - 20px)
    white-space: nowrap
    overflow: hidden !important;
    text-overflow: ellipsis
  &__select
    pa 0
    top 50%
    w 10px
    right 10px
    h 5px
    transform: translateY(-50%);
    &:before
      br-color: $c.blue--light transparent transparent
      color: $c.blue--light
      top 50% !important
      transform translateY(-50%)
      pos absolute
      mt 0
  &__remove 
    color red
    w 20px
    fs 15px  
    pos absolute 
    right 5px 
    d flex
    ai center 
    jc center
    top 50%
    fw 700
    transform translateY(-50%)
  &__tag 
    pos relative
  &__input 
    pt 7px
  &__content-wrapper
    left 0
  &--active
    br-radius 5px 5px 0 0
    z-index 999
    pos relative
    .multiselect
      &__select
        top 50%
        transform translateY(-50%) rotate(180deg)
  &__option
    fs 12px
    pa 12px 5px
    &--selected
      bg: $c.lavender
      color #fff
      &.multiselect
        &__option
          &--highlight
            bg: $c.lavender
    &--highlight
      bg: $c.blue--light
      color: $c.blue--verylight