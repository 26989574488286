.button-list
  d flex

  &__
    &item
      @extend $button
      @extend .button--t-default

      &:not(:last-child)
        border-top-right-radius 0
        border-bottom-right-radius 0

      &:not(:first-child)
        border-top-left-radius 0
        border-bottom-left-radius 0

      &.is-
        &active
          @extend .button--t-primary

  &--
    &primary
      & ^[0]__
        &item
          @extend .button--s-big

