
.arrow
  d flex
  jc space-between
  fd none
  d flex
  ai center
  bg-color: $c.red--dark
  w 100px
  h 50px
  br 0
  br-radius 0 0 5px 5px
  cursor pointer
  jc center
  pos absolute
  +media(from('lg'))
    top 50%
    transform translateY(-50%)
  &__button
    color #fff
    pos absolute 
    top 50% 
    left 10px 
    transform translateY(-50%)
    +media(from('xl'))
      &:hover
        bg-color: $c.blue--dark
    & + &
      ml 5px
  &--prev
    br-radius 0 5px 5px 0
    jc flex-start
    pl 45px
    left 0
    
  &--next
    pr 45px
    jc flex-end
    br-radius 5px 0 0 5px
    right 0    
    .arrow__button 
      right 10px
      left initial
      animation arrow-next 2s infinite linear
  &__text 
    color white 
    ft uppercase
    fw 700 
@keyframes arrow-prev 
  0% 
    transform: translate(0%, -50%)    
  50%
    transform: translate(10px, -50%)    
  100% 
    transform: translate(0%, -50%)    
@keyframes arrow-next
  0% 
    transform: translate(0%, -50%)    
  50%
    transform: translate(-10px, -50%)    
  100% 
    transform: translate(0%, -50%)    