body
  height auto !important
.frame-header
  bg url('/static/img/main.png') no-repeat
  bg-size cover
  bg-position 50% 50%
  br-radius $border-radius
  min-h 500px
  pb 40px
  box-sizing border-box
  pos relative
  // d flex
  // ai flex-end
.navigation
  .section
    &__wrapper
      pa 0
.nav
  w 100%
  pa 0 15px
  box-sizing border-box
  bottom 40px
  pos absolute
  &__item
    w calc(50% - 16px)
    h 94px
    box-shadow -3px 13px 25px 2px rgba(0, 0, 0, 0.46)
    br-radius $border-radius
    d flex
    ai center
    jc center
    bg #fff
    fd none
    f-dir column
    pa 10px
    mt 15px
    box-sizing border-box
    pa--md 15px
    h--sm 125px
    w--lg 210px
    &:hover
      bg-color: $c.red--dark
      .nav
        &__text,
        &__icon
          color #fff
  &__text
    fs 13px
    fw 700
    ft uppercase
    mt 10px
    fs--md 20px
    mt--md 15px
    color: $c.red--dark
  &__icon
    fs 24px
    color: $c.red--dark
@keyframes moving 
  0% 
    transform: translateX(0%)    
  25% 
    transform: translateX(-100%)    
  50% 
    transform: translateX(0%)    
  75% 
    transform: translateX(100%)    
  100% 
    transform: translateX(0%)    
.frame-section
  ma 0
  pa 0
  position relative
  fs 14px
  font-family 'Titillium Web', sans-serif
  // background $darkBlue
  // pa 60px 0
  +media(from('xl'))
    min-h 500px
  p
    ma 0
  .title
    color: $c.red--dark
    font-size 35px
    font-weight 700
    ft uppercase
    lh 30px
    ma 10px 0
    +media(from('lg'))
      fs 39px
    +media(from('xl'))
      ma 0
    &--blue,
    &--popup
      color #272c66
      ft none
    &--blue
      fs 28px
      +media(from('lg'))
        lh 44px
    &--popup
      fs 25px
      ma 0
      lh 28px
      +media(from('lg'))
        fs 30px
        lh 30px
  .subtitle
    fw 400
    fs 15px
    lh 22px
    ft uppercase
    ma 0
    pos relative
    color #272c66
    +media(from('xl'))
      pl 15px
      ml 15px
      fs 18px
      lh 22px
      ta left
      &:before
        content '/'
        pos absolute
        left 0
        bottom 0
    &--normal,
    &--pointer
      ft none
      mt 15px
      +media(from('lg'))
        pl 0
        ml 0
      &:before
        d none
    &--pointer
      fs 15px
      lh 22px
      +media(from('sm'))
        fs 18px
      +media(from('xl'))
        pl 0
        ml 0
        ta center
  .row
    d flex
    jc space-between
    flex-wrap wrap
    w 100%
  section
    pos relative
    min-h 500px
    & + &
      pt 30px
  .frame-container
    w 100%
    pa 0 15px
    ma 0 auto
    ta center
    // +media(from('sm'))
    //   w 480px
    // +media(from('md'))
    //   w 650px
    // +media(from('lg'))
    //   w 768px
    // +media(from('xl'))
    //   w 960px
  .step
    color: $c.red--dark
    ml 5px

  .content
    +media(from('sm'))
      d flex
      flex-wrap wrap
  .col
    w 100%
    +media(from('md'))
      pa 0 8px
    &--align-end
      ai flex-end
      d flex
      mt 20px
    &--sm2-6
      +media(from('md'))
        w 50%
    &--sm1-6
      +media(from('sm'))
        w 50%
    &--sm2-4
      +media(from('md'))
        w 33.33%      
        .button
          &__wrapper
            w 100%  
    &--md-2
      +media(from('xl'))
        w 20%
    &--md-3
      +media(from('xl'))
        w 25%
    &--sm2-7
      +media(from('md'))
        w 66.66%
    &--sm2-8
      +media(from('md'))
        w 75%
    &--xl-9
      +media(from('md'))
        w 16.67%    
    &--xl-12
      +media(from('xl'))
        w 12.5%    
    &--xl-6
      +media(from('xl'))
        w 50%    
    &--xl-4
      +media(from('xl'))
        w 75% 
    &--xl-1
      +media(from('xl'))
        w 100%    
    &--local-flex
      pa 0 !important
      +media(from('md'))
        d flex

    // &--pr-0
    //   +media(from('md'))
    //     pr 0        
  
  .arrow
    d flex
    jc space-between
    fd none
    &__button
      w 77px
      h 33px
      d flex
      ai center
      bg-color: $c.red--dark
      cursor pointer
      br 0
      color #fff
      pa 8px 20px
      
      br-radius 0 0 5px 5px
      jc center
      pos absolute
      +media(from('lg'))
        top 50%
        transform translateY(-50%)
      &--prev
        br-radius 0 5px 5px 0
        jc flex-start
        left 0
      &--next
        jc flex-end
        br-radius 5px 0 0 5px
        right 0    
      &:hover
        bg-color: $c.blue--dark
      & + &
        ml 5px

          
  &__wrapper
    bg #fff
    br-radius 5px
    min-h 500px
  &__content
    pa 0 20px 20px 20px
    +media(from('md'))
      pb 30px
    &--booking
      pos relative
      pb 0
      +media(from('md'))
        pa 35px
      +media(from('lg'))
        pa 80px 35px 35px 35px
  &__heading
    pa 0 23px 15px 23px
    br-bottom 1px solid rgba(56, 61, 114, .15)
    d flex
    ai center
    f-dir column
    pos relative
    +media(from('lg'))
      pa 25px 28px 25px 100px
    +media(from('xl'))
      f-dir row
  &__title
    d flex
    ai center
    f-dir column
    pt 30px
    +media(from('md'))
      pt 0
    +media(from('xl'))
      jc: flex-end
      ai: flex-end
      f-dir row        
.success-message
  pa 20px 
  &__title 
    fs 17px 
    pb 20px 
    fw 700
    text-transform: uppercase
    ta center 
  &__text 
    fs 13px 
    ta center       
.progress-bar
  width 100%
  height 15px
  box-shadow 0 1px 1px #ffffff, inset 0 1px 2px rgba(0, 0, 0, 0.15)
  background-color #ededed
  background-image linear-gradient(to top, rgba(255, 255, 255, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%)                    
  border-radius 10px
  position relative 
  &__status
    background: linear-gradient(45deg, $c.red--dark, $c.blue--dark)
    
    border-radius 10px
    transition 0.5s
    
    position relative
    height 15px
    display block

    .shadow 
      background-color white 
      height 10.5px 
      position absolute
      border-radius 10px 
      opacity 0.3
      top 0
      width 100%
      box-shadow inset 0 0 1px 1px rgba(0, 0, 0, 0.4)
    & p 
      text-align right
      padding-right 10px
      color white 
      font-size 11px
      font-weight 700
      line-height 22px    
.flex-wrap
  d flex
  flex-wrap wrap 
  w 100%      

&::-webkit-scrollbar-track
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
  background-color: #F5F5F5;

&::-webkit-scrollbar
  width: 10px;
  background-color: #F5F5F5;
&::-webkit-scrollbar-thumb

  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: $c.blue--dark