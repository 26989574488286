.tips-wrap
  d: flex
  align-items: center
  justify-content: flex-start
  mb 12px
  flex-wrap: wrap

.tip
  pa 10px 
  br: 1px dashed $c.asphalt
  mr 10px 
  cursor pointer
  pos relative 
  d: flex
  align-items: center
  justify-content: center
  &__title
    fw 700
    fs 16px

.tips
  &__
    &title
      fw 700
      fs 13px
      mb 12px
