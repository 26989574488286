.input-group
  // d grid
  // grid-template-columns: 226px 226px 226px
  // grid-gap: 10px
    // jc space-between
  // &__control
  w 100%
  mt 20px
  +media(from('md'))
    mt 15px
    // +media(from('xl'))
    //   w 210px
  &--no-pa
    mt 0 !important
  &__
    &icon-block
      d flex
      jc: space-between
      mr: 16px
    &icon
      h 80px
      min-h 80px
      w 80px
      min-w 80px
      max-width unset
  &__item
    pos relative
    &--flex
      d flex
      jc space-between
      .input-group
          mt 0 !important
    &--xl-66
      w 100%
      order 1
      mt 10px
      +media(from('md'))
        w 66%
        order 0
        mt 0
    &--flat
      flex-wrap: wrap       
      pb 10px 
      mb 10px 
      jc flex-end
      +media(from('md'))
        pb 0
        jc space-between
        flex-wrap nowrap
      .input-group--multiselect
        w calc(100% - 41px)
        +media(from('md'))
          width 33%
      input[type='number'] 
        w 100% 
        order 2
        +media(from('md'))
          order 0
          w 33% !important
      .input-group__input--not-full    
        w calc(100% - 37px) !important 
        tr 0.5s
        +media(from('md'))
          w 33% !important
      .input-group__delete-btn
        order 2
        +media(to('md'))
          bottom: 10px
          z-index 5
          pos: absolute
        +media(from('md'))
          mt 0
  &--error
    .input-group__error
      d block
  &--multiselect
    .input-group__error
      z-index 999999
  
  &__input
    pos relative
    w 100%
    h 35px
    br 2px solid transparent
    outline none
    bg-color: $c.blue--verylight
    br-radius 5px
    pa 20px 20px 10px 10px !important
    tr 0.5s    
    color: $c.blue--dark
    &::-webkit-input-placeholder
      color: $c.blue--light
      fs 13px
    &:focus 
      br-bottom: 2px solid $c.lavender  
      tr 0.5s
    &--error
      br 2px solid #da251c !important
      pos relative
      tr 0.5s
      &::-webkit-input-placeholder
        color: $c.red--dark
      .multiselect__single
        color: $c.red--dark
        ml -2px
        mt -2px
      .multiselect__select
        right 8px
    &--xl-66
      w 66%
    &--xl-33
      w 32%
      ml 1% !important
    &--full-h
      h: 45px
    &--with-ma
      mb 10px  
      pr 20px  
    &--textarea
      
      font-family 'Titillium Web', sans-serif
      fs 13px
      min-h 62px
      color: $c.blue--dark
      resize none
      overflow auto

      &::-webkit-input-placeholder
        color: $c.blue--light
  &__error

    z-index 10
    fs 11px
    h 11px
    w 14px
    color: $c.red--dark
    pos absolute
    left 0
    top 50%
    transform translate(-44%, -50%)
    content url('./../img/error.png')
  &--multiselect
    .required__error
      transform translate(-44%,16px)
  &--quantity
    d flex
    ai center
    f-dir row
    jc flex-start
    &:nth-child(2)
      mt 10px
  &__title
    color: $c.blue--dark
    fs 13px
    fw 700
    ta left
  &--add
    ta left
    .form__input + .form__input
      mt 15px
  &.button--center
    ma 30px auto 0 auto
    w 100%
    // +media(from('md'))
    //   w 210px
    +media(from('xl'))
      ma 0
      jc flex-end
  &--column
    w 100% !important
    d flex
    jc flex-end
    f-dir column
    +media(from('md'))
      f-dir row
  &__button
    
    +media(from('md'))
      f-dir row
      jc flex-end
    +media(from('lg'))
      f-dir row
      jc flex-end
    +media(from('xl'))
      w 100%
    &--pr-16
      +media(from('md'))
        pr 16px
  &__delete-btn
    color: $c.blue--dark
    pa 5px 
    // pos absolute 
    // right 5px 
    fd none 
    // top 10px
    fs 10px
    bg-color: $c.blue--verylight
    w 30px 
    h 45px 
    d flex
    ai center 
    jc center
    cursor pointer
    ml 1%
    // br: 1px solid $c.blue--dark
    &:hover 
      color: $c.red 
      br-color: $c.red 
  &__count
    color: $c.blue--dark
    pa 5px 
    // pos absolute 
    // right 5px 
    fd none 
    // top 10px
    fs 10px
    bg-color: $c.blue--verylight
    w 30px 
    h 45px 
    d flex
    ai center 
    jc center
    ma 0 1% 0 0 !important
  textarea
    font-family 'Titillium Web', sans-serif
    fs 13px
    min-h 62px
    color: $c.blue--dark
    resize none
    overflow auto

    &::-webkit-input-placeholder
      color: $c.blue--light

  input:-ms-input-placeholder,
  textarea:-ms-input-placeholder
    color: $c.blue--light
  input[type='file']
    d none  
  input[type="date"]::-webkit-calendar-picker-indicator
    color transparent 
    w 100%
    /*
    textarea:-webkit-input-placeholder
      font-family 'Titillium Web', sans-serif
      fs 13px
    textarea:-moz-placeholder
      font-family 'Titillium Web', sans-serif
      fs 13px  */
  &__input:focus + label  

    fs 11px 
    tr 0.5s
    left 10px
    color: $c.lavender
    transform translateY(-10px)
  &__textarea:focus + label  
  
    transform translateY(-10px)
    fs 11px 
    tr 0.5s
    left 10px
    color: $c.lavender
  &__label
    color #57575a
    font-size 13px
    font-weight 700
    pos absolute
    left 10px
    tr 0.5s
    z-index 1
    pointer-events: none
    top 12px
    color: $c.blue--dark
    display: inline-block;
    width: calc(100% - 25px)
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    ta left
    &--static
      pos static
      w 100%
      d block
      ta left
  .is-active-label
    left 10px 
    fs 11px
    color: $c.lavender
    tr 0.5s
    transform: translateY(-10px)  
  input[type='number']
    ma 0 
    pa 0
  @media screen and (-webkit-min-device-pixel-ratio:0)
    select,
    textarea,
    input
      font-size: 13px
  
  .multiselect
    font-family 'Titillium Web', sans-serif
    pa 0 !important 
    border 2px solid transparent
    cursor pointer
    box-sizing border-box
    &__single,
    &__tags
      bg transparent !important
      pa 0
      ma 0
      color: $c.blue--verylight
      fs 13px
      h 40px
    &__tags
      br none
    &__placeholder 
      pt 6px
    &__single
      w 100%
      d flex
      ai center
      pl 3px
      h auto
      fw 500
      pt 5px
      mt 7px
      color: $c.blue--dark
      fs 13px !important
    &__select
      pa 0
      top 50% !important
      w 10px
      right 10px
      h 5px
      transform: translateY(-50%);
      &:before
        br-color: $c.blue--light transparent transparent
        color: $c.blue--light
        top 50% !important
        transform translateY(-50%)
        pos absolute
        mt 0
    &__input 
      pt 7px
    &__content-wrapper
      left 0
    &--active
      br-radius 5px 5px 0 0
      z-index 999
      pos relative
      .multiselect
        &__select
          top 50%
          transform translateY(-50%) rotate(180deg)
    &__option
      fs 12px
      pa 12px 5px
      &--selected
        bg: $c.lavender
        color #fff
        &.multiselect
          &__option
            &--highlight
              bg: $c.lavender
      &--highlight
        bg: $c.blue--light
        color: $c.blue--verylight

.frame-section
  
  .add
    pos relative
    mt -10px
    cursor pointer
    &:before
      content ''
      top 0
      pos absolute
      bg: $c.blue--verylight
      h 4px
      w 100%
      left 50%
      tr 0.5s
      transform translateX(-50%)
    &__button
      w 30px
      h 20px
      fd none 
      cursor pointer
      br-radius 0 0 5px 5px
      ma 0 auto
      mt 5px
      fs 10px
      color: $c.blue--dark
      bg: $c.blue--verylight
      d flex
      ai center
      tr 0.5s
      jc center
    &:hover
      &::before
       bg: $c.lavender
       tr 0.5s
      .add__button 
        tr 0.5s
        bg: $c.lavender   
        color white 
  .extend
    w 100%
  .button-wrapper
    &+&
      mt 15px
      +media(from('md'))
        ml 16px
        mt 0
    &--174
      w 174px
      pos absolute
      bottom -8px
      left 50%
      transform translateX(-50%)
      
    &--sm2-284
      w 100%
      +media(from('md'))
        w 284px
    &--sm2-210
      w 100%
      +media(from('md'))
        d block
        w 210px
    &--sm2-4
      w 100%
      +media(from('md'))
        w 177px
      +media(from('lg'))
        w 206px
    &--captcha
      overflow hidden
      mb 20px
      +media(from('md'))
        mb 0
      iframe
        ml 28px
        transform scale(.82)!important
        +media(from('sm'))
          ml 0
          mt 7px
        //   transform scale(1) !important  
  .buttons
    d flex
    f-dir column
    +media(from('md'))
      f-dir row
      jc flex-end
    &--end 
      ai flex-end  


  .button
    w 100%
    br 0
    d flex
    jc center
    ai center
    br-radius 5px
    bg-color: $c.red--dark
    h 40px
    outline none
    cursor pointer
    &:hover
      bg-color: $c.blue--dark
    &+&
      mt 20px
      +media(from('md'))
        mt 0
    &--cards
      &:hover
        br: 3px solid $c.red--dark
    &--default
      bg-color: $c.blue--verylight
      &:hover
        .button
          &__text
            color #fff
      +media(from('md'))
        d flex
      .button
        &__text
          color: $c.blue--light
    &--green
      bg-color green
      &:hover
        bg-color: $c.blue--dark
    &--blue
      bg-color: $c.blue--dark
      &:hover
        bg-color: $c.red--dark
    &--popup
      +media(from('md'))
        w 100%
    &__text
      color #fff
      fw 700
      &--pointer
        color: $c.blue--light
        cursor default
          
.is-error
  iframe 
    br: 2px solid $c.red--dark
.datepicker
  pos relative
  d flex
  &__icon
    pos absolute
    max-w 30px
    max-h 30px
    w 30px
    pointer-events: none
    h 30px
    color: $c.blue--light
    right 0px
    top 55%
    z-index 555
    fs 12px
    transform translateY(-50%)
    i
      max-w 30px
      max-h 30px
      w 30px
      h 30px
      &:before
        max-w 30px
        max-h 30px
        w 30px
        h 30px
        // bg-color: $c.blue--verylight
        pos absolute
        lh 30px
        left 0
  input
    cursor pointer     
    min-w calc(100% - 15px)