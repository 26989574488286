@b list-table
  width 100%

  border-collapse collapse
  border-spacing 0
  // table-layout fixed
  overflow visible

  @e
    &head
    &footer
      bg: $c.relief--dark

      @bn
        @e
          &cell
            fw bold
            va middle

    &body
    &footer
    &row
    &item
    &cell
      overflow visible

    &body
      @m
        &even-offsets
          @bn
            @e
              &row:first-child .list-table__cell
                pt unquote('calc(%s * 2)' % (2sp))

    &row
      // &:nth-of-type(2n - 1)

      @m
        &odd
          bg: $c.relief

        &even
          //

        &clickable
          cursor pointer

          &:hover
            bg: $c.relief--dark

        &highlight
          br: 1px solid $c.blue
          // br-bottom 0px

        &highlight&clickable
          br-bottom 0px
        &is-new
          bg: rgba($c.green, 0.2)
        &error
          br-left: 4px solid $c.red
          // bg: $c.red !important
          // color #fff !important

        &centered
          @bn
            @e
              &cell
                va middle
                ta center

    &cell
      pos relative
      pa 2sp
      va top
      ta left
      +media(to('sm'))
        pa 2px
      .field-wrapper
        mb 0 !important

      @m
        &s-
          for $i in 1..12
            &{$i}
              width $i * (100% / 12)

        &checkbox
          w 4.2sp

        &bordered
          br-l: 1px solid $c.relief
          br-r @br-l

        &centered
          va middle
          ta center

        &middle
          va middle

        &actions
          width 35px
          white-space nowrap

        &last
          ta right

        &index
          w 10px
          fw 900

        &f-
          &ordering
            w 45px

        &condensed
          pv 1sp

        &square
          width 36px

          &.list-table__cell--condensed
            width 28px
            height 28px

            max-w 28px

        &one-line
          white-space nowrap
          overflow hidden
        &hide-on-mob
          +media(to('sm'))
            d none
        &vertical
          h 15ch
          overflow hidden
          text-overflow ellipsis

          & > div
            pos absolute
            top 100%
            left 0
            pa @pa
            transform-origin 0 0
            transform rotate(-90deg)
            white-space nowrap

        &error
          br-left: 4px solid $c.red
        &field-
          &date
            w: 100px 
            a 
              white-space: nowrap
          &trucks, &employees
            w: 40px      
          &description
            max-w: 300px
    &icon
      &:hover
        color: $c.asphalt
    &item
      // d flex
      d none
      bg: $c.relief--dark
      br: 1px solid $c.blue
      br-top 0px

      box-shadow: inset 0 0 25px $c.relief--dark

      @s
        &active
          d table-row
      &--flex
        .form__row
          d flex
          flex-wrap wrap
          .form__cell
            w 33%
            &--full
              flex-basis: initial !important
            +media(from('xl'))
              w 25%
      @bn
        @e
          &cell:first-child
            pa 3sp 4sp

    &subtitle
      fw 200
      fs 1.1em

    &title
      fs bold
      fs 1.1em

  @m
    &summary
      ml 50%
      w 50%

      @bn
        @e
          &cell
            &:first-child
              fw bold

    &fixed
      +media(from('xl'))
        table-layout auto
    &pined
      +media(from('xl'))
        table-layout fixed

    &bordered
      br: 1px solid $c.relief--dark

      @bn
        @e
          &cell
            br @br

  // .history-card__section &
  // .list *:not(.list-table-overflowable) > & &
  .formset--day_salaries &
    @media (max-width: 960px)
      d block

      tbody
        d block

      &__
        &selection
          .form
            &__
              &row
                d block

              &cell
                w 100%

        &head
          d none
        &row
          d flex
          flex-wrap wrap
          border-bottom: 1px solid $c.blue

        &cell
          d block
          w 100%

          &:before
            d block
            bg: $c.relief--dark
            pa 2px
            fw bold
            fs 0.85em
            ma -2sp
            mb 2sp
            content attr(data-title) '\00A0'

          // &[data-title]:before

          &--
            &checkbox
              w 38px !important
              & + *
                w calc(100% - 38px)

            &actions
              w 100% !important
              ta center !important

        &item
          w 100%

          td
            d block

          .form
            &__
              &row
                d block

              &cell
                w 100%

          .list-table
            &__
              &cell
                w 100% !important
                &:before
                  d none

          &.is-active
            d block

    @media (min-width: 350px) and (max-width: 520px)
      &__
        &cell
          w 50% !important

          &:last-child:nth-child(2n+1):not(.list-table__cell--actions)
            w 100% !important

          &--
            &checkbox
              & + *
                w calc(50% - 38px) !important

    @media (min-width: 520px) and (max-width: 960px)
      &__
        &cell
          w 33.333% !important

          &:last-child:nth-child(3n+2):not(.list-table__cell--actions)
            w 66.666% !important

          &:last-child:nth-child(3n+1):not(.list-table__cell--actions)
            w 100% !important

          &--
            &checkbox
              & + *
                w calc(33.333% - 38px) !important


@b cell-filler
  pos absolute
  top 0
  bottom 0
  d flex
  align-items center
  justify-content center
  min-w 50%

  fw bold
  ta center
  fd none

  bg: $c.asphalt--light
  color #fff

  @m
    &full
      w 100%
      left 0

    &am
      left 0

    &pm
      right 0
      bg: $c.asphalt

    &corner
      overflow hidden
      padding 50%

      &.cell-filler--
        &am
        &pm
          &:before
            width 100%
            height 100%

          bg transparent

          &:before
            top 0
            bottom 0
            pos absolute
            d block
            content ''
            transform-origin 0 0
            transform skewX(45deg)

        &am
          &:before
            right 100%
            bg: $c.asphalt--light

        &pm
          &:before
            bg: $c.asphalt
            left 0

    &status
      $statuses = ( \
        ('IN_PROCESS'    0 $c.violet) \
        ('RECEIVED'      1 $c.cyan) \
        ('NOT_CONFIRMED' 2 $c.yellow) \
        ('CONFIRMED'     3 $c.blue) \
        ('IN_WORK'       4 $c.orange) \
        ('DONE'          5 $c.green) \
        ('CANCELED'      6 $c.red) \
      )

      &-
        for $status in $statuses
          &{$status[1]}
            &
            &:before
            &.cell-filler--am:before
              bg $status[2]

            &.cell-filler--pm
            &.cell-filler--pm:before
            &.cell-filler--pm:before
              bg darken($status[2], 20%)

            &.cell-filler--corner.cell-filler--am
            &.cell-filler--corner.cell-filler--pm
              bg transparent

            if contrast(#fff, $status[2]).ratio >= 2
              color #fff
            else
              color #000

  @s
    &highlight
      outline: 5px solid $c.yellow
      z-index 2
      // transition all .2s ease
      // transform translateY(-5px) translateZ(0)
      // box-shadow 0 5px 15px #000c

.list-table-overflowable
  overflow auto

.table-toggle
  d: flex 
  ai: center 
  jc: center  
  w: 30px
  h: 30px 
  fd: none 
  fs: 20px

.table-text
  white-space: nowrap

.cell-wrapper--phone
  display: flex
  align-items: center
.cell-wrapper__label
  margin-right: 12px
.cell-wrapper__bnt
  display: flex
  justify-content: center
  align-items: center
  height: 34px
  width: 34px
  color: #fff
  background-color: #129fdd
  text-decoration: none
  border-radius: 3px
