@require '../aliases'
@require 'breakpoints'

$ResponsiveProperties = ( \
  'bottom' \
  'top' \
  'right' \
  'float' \
  'left' \
  'color' \
  'mh' \
  'mv' \
  'ph' \
  'pv' \
  'br-h' \
  'br-v' \
  'wf' \
  'flex-grow' \
  'align' \
  'fs' \
  'fw' \
)

responsive-property()
  if current-property
    $pb = split('--', current-property[0])
    $prop = $pb[0]
    $bp = $pb[1]

    $q = unquote('(min-width: %s)' % $ResponsiveBreakpoints[$bp])

    @media $q
      {$prop} arguments


responsive-make($list)
  for $prop in $list
    for $bp in $ResponsiveBreakpoints
      define('' + ($prop + '--' + $bp), responsive-property, true)


for $list in (keys($Aliases) values($Aliases) $ResponsiveProperties)
  responsive-make($list)
