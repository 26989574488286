.review-wrap
  &__title 
    fs 25px 
.review-question
  mb 20px
  &__title 
    fs 1em
    fw 500
    ma 0 
    pa 0
    mb 10px
  &__text 
    fs 12px 
    fw 500
  &__social-media-links
    d flex
    // jc space-between
    ai center
    flex-wrap: wrap
  &__social-media-link
    mr 16px
    mb 16px
  &__social-media-icon
    h: auto
    w: 200px
    object-fit: contain
    object-position: center
    d: block

.star-wrap
  d flex 
  ai center 
  jc flex-end
  flex-direction: row-reverse
  h 40px
  &__text 
    fs 11px
    ml 10px


.person-rate
  mb 20px
  &__img 
    w 50px 
    h 50px 
    br-radius 100% 
    d block 
  &__row 
    d flex 
    ai center 
  &__name 
    ml 10px
  &__text 
    fs 12px 
    fw 500