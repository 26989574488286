.upload
  d flex
  pos relative
  flex-wrap wrap
  &__video
    pos relative
    d flex
    f-dir column
    mt 15px
    jc space-between
    w 100%
    pos relative 
    h 34px 

    // +media(from('md'))
    //   pl 9px
    // +media(from('xl'))
    //   pl 5px
    .pointer
      pos absolute
      top -20px
      +media(from('md'))
        top -34px
        d flex
  &__title
    color: $c.blue--light
    fs 13px
    ta left
    mb 15px
    +media(from('md'))
      mb 0
  &__remove
    pos absolute
    cursor pointer
    right 0
    bottom 0
    h 9px
    w 9px
    color: $c.red--dark !important
    fs 9px
    +media(from('md'))
      top 0
.pointer
  min-w 210px
  cursor default
  pos relative
  box-shadow 0 1px 1px 1px rgba(72, 85, 96, 0.25), inset 0 0 0 1px rgba(255, 255, 255, 0.5), 0 0 0 rgba(0, 0, 0, 0.13), inset 0 0 0 1px rgba(255, 255, 255, 0.2)
  bg-color #ffffff
  bg-image linear-gradient(to top, rgba(63, 68, 72, 0.1) 0%, rgba(255, 255, 255, 0) 100%)
  br-radius 5px
  &__text
    fs 12px
    fw 400
    color: $c.blue--light
    cursor text
  &--calculate
    ma 30px 0
    w 100%
    box-shadow none
  &__wrapper
    h 100%
    pa 25px 14px
    box-shadow none
    bg-image none
    br 3px solid $c.blue--verylight
    
    &:after
      box-shadow -3px 3px 0 0 rgb(241, 242, 246)
      bottom auto
      top 5px
      left 50%
      transform translateX(65%) rotate(135deg)
    &--video
      br 0
      d flex
      jc center
      ai center
      br-radius 5px
      bg-color #fff
      pa 10px
      w 100%
      h 40px
      outline none
      &:hover
        bg-color #fff
        bg-image inherit
      &:after
        content ""
        pos absolute
        w 0
        h 0
        top auto
        bottom -14px
        left 50%
        transform translateX(-70%) rotate(-45deg)
        
        br 7px solid #fff
        br-color transparent transparent #fff #fff
        transform-origin 0 0
        box-shadow -3px 3px 3px 0 rgba(0, 0, 0, 0.13)


