.light-gallery-wrapper
  w 90%

.gallery-slider
  // ma 0px -15px
  +media(from('xl'))

    .controls
      &-left 
        left 0px 
      &-right 
        right -13px  
.light-item
  h 280px
  
  pos relative
  overflow hidden
  +media(from('lg'))
    h 300px
  +media(from('xl'))
    h 400px
  &:hover
    .light-item__loop
      opacity 1
      tr 0.5s
      z-index 999
      // transform: translateY(-50%) translateX(-50%)
  &__thumbnail
    ma 15px
    d block
    h 100%
  &__img
   
    pos absolute
    top 50%
    transform: translateY(-50%) !important
    h auto
    // max-w 100%

    w 100%
   
    d block
  &__loop
    pos absolute
    top 50%
    left 50%
    z-index 1
    br-radius 50%
    bg-color: $c.asphalt
    cursor pointer
    transform: translateY(-50%) translateX(-50%)
    w 65px
    h 65px
    d flex
    ai center
    jc center
    transition: all .5s ease
    opacity 0
  &__icon
    color #fff
    font-size 24px

.controls 
  text-shadow 6px 10px 21px rgba(0, 1, 5, 0.6)
  color white
  fw 700
  fs 25px
  w 49px 
  h 49px
  pos absolute 
  d flex 
  ai center 
  jc center
  top 50%
  bg:#4d5a75
  z-index: 555
  box-shadow 0 28px 28px 2px rgba(55, 75, 85, 0.08)
  transform: translateY(-50%)
  tr 0.5s 
  +media(from('xl'))
    &:hover 
      opacity 0.5
      tr 0.5s 
  &--black 
    bg $text
  &-left 
    left 0
    +media(from('sm'))
      left -13px 
    &--without-grid
      opacity 0.35
      +media(from('sm'))
        left -30px
      +media(from('xl'))
        left -60px
  &-right
    right 0
    +media(from('sm'))
      right -13px  
    &--without-grid
      opacity 0.35
      +media(from('sm'))
        right -30px
      +media(from('xl'))
        right -60px
  &--mini
    w 30px 
    h 30px
