.counter 
  d flex 
  ai center 
  jc flex-start 
  bg  #f6f7f9
  &__icon 
    color: $c.blue--dark
    w 25px 
    h 25px
    d flex
    ai center 
    jc center 
    fd none
    fs 10px
    tr 0.5s
    flex-shrink 0
    // br 2px solid $text 
    bg  #f6f7f9
    &:hover 
      color: $c.red--dark
      tr 0.5s
      // br-color $color-warning
    &--disabled
      opacity 0.3
      tr 0.5s      
      pointer-events: none 
  &__number 
    color #57575a
    font-size 13px
    font-weight 500
    pa 0 10px  
    width 40px
    ta center 
    bg  #f6f7f9
    br none 
    outline: none
    pb 0!important      
    d block !important
.choose
  w 90%
  jc space-between
  d flex
  &__delete-btn 
    color white 
    bg: $c.red
    h 32px 
    w 32px 
    d flex
    flex-shrink 0
    ai center 
    jc center
    cursor pointer
    fw 700
    text-decoration: none
  .counter
    h: 32px
.counter-wrap
  d flex
  w 100%
  flex-wrap wrap
  &__block
    ta left
    mt 15px
    w 100%
    +media(from('sm'))
      w 49%
    +media(from('lg'))
      w auto
      mt 30px
  &__title
    color: $c.blue--dark
    fs 16px
    d flex
    h 40px
    fw 700
    ta left