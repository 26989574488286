.new-calls
  pos: absolute
  top: 0 
  left: 50%
  transform: translateX(-50%)
  display: flex
  jc: center
  f-dir: column
  z-index 55



.new-call
  d: flex
  padding: 0.5rem 1rem;
  // padding-right: 2rem;
  margin: 0.25rem 0;
  position: relative;
  border: 1px solid #c8d3dc;
  border: 1px solid rgba(200, 211, 220, 0.4);
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.5);
  border-radius: 3px;
  overflow: hidden;
  color: #fff;
  fs: 13px
  d: flex
  jc: space-between
  ai: center
  &__
    &icon
      d: block
      mt: 4px
    &part
      d: flex
      ai: center
      ai: flex-start
    &btn
      @extend .button
      @extend .button--c-green
      cursor: pointer
    &content
      ml: 10px  
      mr: 10px  
    &phone, &client
      fw: 700
  p
   ma: 0 !important
   pa: 0 !important
      