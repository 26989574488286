@b employees-modal
  @e
    &row
      clear fix
      d flex
      flex-wrap wrap

      @m
        &s-
          for $i in 2..6
            &{$i}
              .employees-modal__col
                w calc(100%)
                +media(from('lg'))
                  width (100% / $i)
                  float left
      @m 
        &center 
          ai center
          jc flex-start
        &sb 
          ai center
          jc space-between
    &col
      //

    &section
      mb 4sp

    &showups
      overflow hidden
      mb 3sp
      pa 2sp
      br: 1px solid $c.relief--dark
      br-radius 3px

      &-
        &title
          pr 2sp
          display table-cell
          va middle

          ft uppercase
          fw bold
          fs 3ty

        &list
          display table-cell
          va middle
          w 10000px
          w 100vw

    &showup
      d inline-block
      mr 2sp

      & span
        d inline-block
        va middle

      & .icon
        cursor pointer

