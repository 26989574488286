.timepicker
  w 100%
  d flex 
  jc space-between 
  ai center
  .input-group__input 
    w 49% 
    h 35px
    min-w initial !important
  .v-picker 
    pos absolute 
    z-index 99999999999999
    left 0