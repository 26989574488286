$pp-columns-count = 12 
$pp-columns-calculation-error = 0.00001

columns($count, $total = $pp-columns-count, $error = $pp-columns-calculation-error)
  100% * $count / $total - $error

.{$PREFIX}cols
  min-width: 0
  &--
    &fullwidth
      +both-adaptive-block()
        width 100%
    &auto
      +both-adaptive-block()
        max-width: none
        width: auto
        flex-basis: 0
        flex-grow: 1
        flex-shrink: 1

    &narrow
      +both-adaptive-block()
        width: auto
        flex: none
    
    &w290-till-sm
      & > div
        @media(max-width: 567px)
          width 290px
          mh auto
    

    for $i in (1)..(10)
      &{$i}_from_10
        $columns = columns($i, $total: 10)
        +both-adaptive-block()
          width: $columns
          flex: none
          &.{$PREFIX}cell--visible_
            for $size, $value in $media-group
              &{$size}
                width 'calc(%s + 4px)' % $columns

    for $i in (1)..($pp-columns-count)
      &{$i}
        $columns = columns($i, $total: $pp-columns-count)
        +both-adaptive-block()
          width: $columns
          flex: none
          &.{$PREFIX}cell--visible_
            for $size, $value in $media-group
              &{$size}
                width 'calc(%s + 4px)' % $columns





