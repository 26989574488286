+media(to('sm'))
  // .list-table__cell[data-title='   Date    ']
  //   word-break: break-all;
  .list-table__cell[data-title='   ID    ']
    word-break: break-all;
  // .list-table__cell[data-cell='Date']  
    // word-break: break-all;
  .form__row
    &--flex  
      d flex !important
      flex-wrap wrap
  .list-table
    &__mob-id
      pr 5px
      white-space nowrap
  .nospase    
    white-space nowrap
  .list-table__cell--actions
    w 15px !important  
  .mobile-table

    .list-table
      &__head 
        d none
      &__row 
        border-bottom: 10px solid #ddd;
        display: block;
        margin-bottom: 10px;
        &:after 
          content 'Next \2b07'
          d block 
          w 100%
          fw 700
          border-top: 10px solid #ddd;
        &:last-child::after 
          content ''  
        
      &__cell
        border-bottom: 1px solid #ddd;
        w 100%
        display: flex;
        jc space-between
        flex-wrap wrap
        text-align: right;
        &--float 
          d block 
        &--actions
          w 100% !important
          ai center 
          jc center
          .button 
            min-w 100px
            d flex 
            ai center 
            jc center 
        &::before
          content: attr(data-title);
          float: left;
          font-weight: bold;
          text-transform: uppercase;
     
      &__item.is-active 
        d flex 
        flex-wrap wrap 
        .form__row
          w 100% 
          flex-wrap wrap
        .form__cell
          flex-grow inherit !important  
          flex-basis inherit !important  
          w 100%
          ta left
        .list-table__cell
          &:before 
            content ''
            d none


#small-screen
  +media(to('sm'))
    .tabs 
      .form
        &__row
          d block !important