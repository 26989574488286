@b sidebar
  $border = 1px solid $c.asphalt--dark

  h 100%
  pos fixed
  left 0
  top 0
  z-index 95

  bg: $c.asphalt
 
  
  @e
    &inner
      d flex
      height 100%
      flex-direction column
      justify-content center

    &menu
      flex: 1 1 auto
      
      overflow-y: auto
      list-style none
      pa 0
      ma 0
      &::-webkit-scrollbar-track
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        border-radius: 10px;
        background-color: #F5F5F5;

      &::-webkit-scrollbar
        width: 5px !important;
        background-color: #F5F5F5;
      &::-webkit-scrollbar-thumb

        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: $c.blue--dark
    &item
      //

    &link
      d block
      // pa 2sp 2sp 

      // pa--md 3.5sp 3sp
      td none
      ta left
      // pa 16px
      color #fff
      br-bottom $border
      cursor pointer
      pa 8px
     
      @s
        &active
          bg: $c.asphalt--dark
          color: $c.blue

      &:hover
        bg: $c.blue
        color: #fff

    &icon
      @extends $icon--rotatable

      ta center
      fs 25px
      fs--md 35px
      fh 1
      va middle

      @m
        for $i in ('chevron-right' 'cog')
          &{$i}
            icon($i)

    &label
      d none

    &toggle

      @bn
        @e
          &link
            pv 1sp
            pv--md 3sp

          &icon
            fs 5ty

            &:before
              fh $header-height

    &gotop
      @bn
        @e
          &link
            pv 3sp

            br-bottom 0
            br-top $border

          &icon
            fs 1ty

            &:before
              fh: $Font.height.body

  @s
    &active
      width auto
      z-index 100

      @bn
        @e
          &toggle
            @bn
              @e
                &icon
                  transform rotate(180deg)

          &label
            d inline-block
            fs 2ty
            ml 3sp
            color #fff !important
            ft uppercase
