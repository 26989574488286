.content
  d block
  min-h 100%

  fw 300
  bg #fff

  @e
    &inner
      $border = 1px solid $c.relief--dark
      pa 1sp 2sp
      pa--md 3sp 4sp

      @m
        &header
          br-bottom $border

        &footer
          br-top $border

          // fs 2ty
          color: $c.asphalt--faded
