

$media-size-xs = 0px 
$media-size-sm = 567px 
$media-size-md = 768px 
$media-size-lg = 992px 
$media-size-xl = 1200px
$media-size-mac = 1440px

$media-group = {
  xs: 0px,
  sm: 567px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  mac: 1440px,
}




cssvar-definition()
  /:root
    { block }
  { block }


// $media-query-xs = "(min-width: {$media-size-xs})"
// $media-query-sm = "(min-width: {$media-size-sm})"
// $media-query-md = "(min-width: {$media-size-md})"
// $media-query-lg = "(min-width: {$media-size-lg})"
// $media-query-xl = "(min-width: {$media-size-xl})"
// $media-query-max-xs = "(max-width: {$media-size-xs})"
// $media-query-max-sm = "(max-width: {$media-size-sm})"
// $media-query-max-md = "(max-width: {$media-size-md})"
// $media-query-max-lg = "(max-width: {$media-size-lg})"
// $media-query-max-xl = "(max-width: {$media-size-xl})"

mq-xs()
  @media (min-width $media-size-xs)
    { block }

mq-sm()
  @media (min-width $media-size-sm)
    { block }

mq-md()
  @media (min-width $media-size-md)
    { block }

mq-lg()
  @media (min-width $media-size-lg)
    { block }

mq-xl()
  @media (min-width $media-size-xl)
    { block }
mq-mac()
  @media (min-width $media-size-mac)
    { block }

mq-mac()
  @media (min-width $media-size-mac)
    { block }

mq-till-xs()
  @media (max-width $media-size-xs)
    { block }

mq-till-sm()
  @media (max-width $media-size-sm)
    { block }

mq-till-md()
  @media (max-width $media-size-md)
    { block }

mq-till-lg()
  @media (max-width $media-size-lg)
    { block }

mq-till-xl()
  @media (max-width $media-size-xl)
    { block }

mq-till-mac()
  @media (max-width $media-size-mac)
    { block }

adaptive-block($separator = '-')
  { block }

  &{$separator}xs
    +mq-xs()
      { block }

  &{$separator}sm
    +mq-sm()
      { block }

  &{$separator}md
    +mq-md()
      { block }

  &{$separator}lg
    +mq-lg()
      { block }

  &{$separator}xl
    +mq-xl()
      { block }
  
  &{$separator}mac
    +mq-mac()
      { block }

adaptive-block-till($separator = '-till-')
  { block }

  &{$separator}xs
    +mq-till-xs()
      { block }

  &{$separator}sm
    +mq-till-sm()
      { block }

  &{$separator}md
    +mq-till-md()
      { block }

  &{$separator}lg
    +mq-till-lg()
      { block }

  &{$separator}xl
    +mq-till-xl()
      { block }
  &{$separator}mac
    +mq-till-xl()
      { block }

both-adaptive-block()
  +adaptive-block()
    { block }

  +adaptive-block-till()
    { block }

