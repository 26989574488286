.v--modal-overlay .v--modal-box
  overflow inherit !important
// .v--modal
//   .multiselect
//     h 34px 

@b modal
  pos fixed
  top 0
  left 0
  w 100%
  h 100%
  z-index 1000
  d none

  @e
    &bg
      pos fixed
      top 0
      left 0
      w 100%
      h 100%
      z-index 1
      bg: #0009

    &body
      d flex
      top 0
      left 0
      w 100%
      h 100%
      overflow auto
      position relative
      z-index 2
      align-items center
      justify-content center

    &inner
      d block

      @m
        &s-
          &small
            @media (min-width: 320px)
              w 320px

        &s-
          &normal
            @media (min-width: 540px)
              w 540px

        &s-
          &medium
            @media (min-width: 720px)
              w 720px

        &s-
          &large
            @media (min-width: 1180px)
              w 1180px
            pa 3sp

    &window
      pos relative
      pa 3sp
      br-radius 6px
      z-index 3
      mv auto

      bg #fff
      box-shadow 0 10px 35px #0005
      transition width .3s all

      min-w 150px
      min-h @min-w

      &:empty
        w 150px !important
        h @w !important

    &close
      pos absolute
      top 0
      right 0
      pa .25rem .3rem
      fd none
      border-top-right-radius 6px
      border-bottom-left-radius 6px

      fs 6ty
      fh 5ty
      fw bold
      font-family Times New Roman
      cursor pointer

      bg: $c.red
      color #fff

    &header
      pb 3sp
      mb 4sp
      br-bottom: 2px dotted $c.relief--dark

      fs 25px
      fw 900

      & small
        fs .6em
        fw 400
        va baseline

    &content
      ph 2sp

  @m
    &auth
      @bn
        @e
          &bg
            bg url('/static/img/bg--auth.jpg') center center
            bg-size cover

          &header
            ta center
            ft uppercase
            pt 3sp
            pb 4sp

            & small
              d block

          &content
            ph 3sp

            ta center

    &dialog
      @bn
        @e
          &window
            min-height 0

          &content
            ph 0


  @s
    &active
      d block

    &loading
      @bn
        @e
          &window
            overflow hidden

            &:after
              pos absolute
              top 0
              right 0
              bottom 0
              left 0
              z-index 10
              d block

              bg-color #fff9
              bg-image url('/static/img/load.svg')
              bg-position center center
              bg-repeat no-repeat
              content ''
              user-select none


@b dialog
  d flex
  ma -2sp
  ml 0

  @e
    &content
      flex-grow 1
      d flex
      align-items center
      justify-content flex-start

    &controls
      d flex
      align-items center
      justify-content flex-end

    &control
      flex-grow 1
      pl 2sp


.pop-bounce-transition
  transition .2s .1s


.pop-bounce-enter
  opacity 0
  transform translate3d(0, 0, 0) scale(.7)


.pop-bounce-leave
  opacity 0
  transform: translate3d(0, 0, 0) scale(1)
