$ic = {
  arrow-right:   "\e900"
  bell:          "\e901"
  calendar:      "\e902"
  check:         "\e903"
  chevron-right: "\1f30a"
  cog:           "\e906"
  diagram:       "\e907"
  dot:           "\e908"
  minus:         "\e909"
  money:         "\e90a"
  papers:        "\e90b"
  path:          "\e90c"
  pen:           "\e90d"
  people:        "\e90e"
  plus:          "\e90f"
  question:      "\e910"
  times:         "\e911"
  trigon-down:   "\e912"
  search:        "\e904"
  human:         "\e905"
  plus-square:   "\e913"
  save:          "\e914"
  times-circle:  "\e915"
  upload:        "\e916"
  error:         "\e001"
  warning:       "\e002"
  person:       "\EA23"
}

$ic-sizes = ( \
  ('sm' 0ty) \
  ('nm' 3ty) \
  ('bg' 4ty) \
  ('lg' 5ty) \
  ('mg' 6ty) \
  ('gi' 7ty) \
)


@font-face
  font-family 'icomoon'
  src url($icon-path + '/icomoon.eot')
  src url($icon-path + '/icomoon.eot#iefix') format('embedded-opentype'),
      url($icon-path + '/icomoon.ttf') format('truetype'),
      url($icon-path + '/icomoon.woff') format('woff'),
      url($icon-path + '/icomoon.svg#icomoon') format('svg')
  font-weight normal
  font-style normal

$icon
  font-family 'icomoon' !important
  speak none
  font-style normal
  font-weight normal
  font-variant normal
  text-transform none
  line-height 1

  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale


$icon--rotatable
  @m
    &r
      for $i in (90 180 270)
        &{$i}
          transform rotate(unit($i, deg))


icon(name, sub='before')
  &:{sub}
    @extends $icon

    d inline-block

    content $ic[name]


.icon
  @extends $icon

  $colors = ( \
    ('red' $c.red) \
    ('blue' $c.blue) \
    ('green' $c.green) \
  )

  $actions = ( \
    ('delete' $c.red) \
    ('update' $c.blue) \
  )

  @m
    for $i in $ic
      &{$i}
        icon($i)

    for $s in $ic-sizes
      &s-{$s[0]}:before
        fs $s[1]

    &c-
      for $c in $colors
        &{$c[0]}
          color $c[1]

    &a-
      for $a in $actions
        &{$a[0]}
          color $a[1]


.icon--person
  &:before
    font-family: "svgfont" !important