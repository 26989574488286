$header-height = 4.5sp
$header-sp = 0
$header-inner-height = unquote(s('calc(%s - %s * 2)', $header-height, $header-sp))

@b header
  h $header-height
  fw 400

  @e
    &inner
      d flex
      h $header-height
      pv $header-sp

    &notifications
    &title
    &user
    &exit
      h 100%

    &notifications
    &user
      br-right: 1px solid $c.relief--dark

    &title
    &exit
      fh $header-inner-height

    &notifications
      wf 75px
      mr 4sp

    &title
      flex-grow 1
      fw 900
      fs 2ty
      fs--md 4ty
      ft uppercase

      &, & > *
        min-w 0
        max-w 100%
        white-space nowrap
        overflow hidden
        text-overflow ellipsis

    &user
      // wf 320px
      pr 2sp
      pr--md 4sp
      max-w 320px
      white-space nowrap

    &exit
      +media(to('sm'))
        & a
          text-decoration: none
          fs 10px
          font-size: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: row;
          width: auto;
          height: auto;
          padding: 0;
          pl 5px
          margin: 0;
          &:after 
            content '\F206'

            font: normal normal normal 24px/1 "Material Design Icons";
            font-size: 20px;
            top 0
            right 0 
            position: relative;
            padding: 0;
            margin: 0;
            height: auto;
            width: auto;
            text-decoration: none;

      +media(from('md'))

        icon arrow-right after

        max-w 110px
        pl 2sp
        pl--md 4sp
        ta right
        fs 15px
        ft uppercase

        a
          td none
          color inherit


  @media (max-width: 768px)
    &__
      &title
        d none

      &notifications
        w auto
        mr 0
        pr 3sp

      &user
        flex-grow 1

        .user-bar
          &__avatar
            d none



@b notifications-center
  $tsize = 36px

  d flex
  ta center
  h 100%
  afc()

  @e
    &toggle
      d block
      pos relative
      w $tsize
      h @w
      ma auto
      ml 0

      br-radius 999px

      bg: $c.blue

    &icon
    &clean-icon
      color #fff
      fs $tsize * .6
      fh $tsize
      ta center

      cursor pointer

      &:before
        fh @fh
        ml 1px
        va top

    &icon
      icon bell

    &counter
      pos absolute
      top -5px
      right @top
      w 20px
      h @w

      fs 12px

      br-radius 99px

      bg: $c.green
      color #fff


@b user-bar
  @e
    &toggle
      d flex
      h 100%
      max-h @h

      fd none
      color inherit

    &avatar
      overflow hidden
      br-radius 999px
      h $header-inner-height
      wf @h

      img
        max-h $header-inner-height

    &username
      pl 3sp
      fh $header-inner-height
      ft uppercase
      fs 15px

    &icon
      icon cog
      fh $header-inner-height
      pl 1sp
      fs 3ty
      color: $c.blue

