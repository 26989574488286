.uploads-photo-row 
  w 100% 
  d flex 
  jc flex-start
  flex-wrap: wrap
  &.order-asset-list
    .form-control 
      +media(to('md'))
        w: calc(33% - 20px)
  .form-control 
    mr 20px
    pos relative
    mt 20px
    &__img-wrapper 
      width 100px 
      mb 10px
      h 100px 
      overflow hidden
      position relative
      d: block
      fd: none
      +media(from('xl'))
        w 100px 
        h 100px
      & img 
        pos absolute 
        w 100% 
        h auto 
        top 50% 
        left 50% 
        transform translateX(-50%)translateY(-50%)
    &__link 
      pos absolute 
      fd none
      cursor pointer
      top 50%
      left 50%
      transform translateX(-50%) translateY(-50%)    
      width 30px 
      color white
      tr 0.5s
      
      height 30px 
      fs 30px
  .error
    fs 11px
    fw 700 
    color white
    top -52px
    left 20px
    width 225px
    // visibility: hidden;
    // width: 120px;
    background-color: RGBA(0,0,0,.4);
    // text-align: center;
    border-radius: 6px;
    padding: 5px;
    tr 0.5s
    position: absolute;
    z-index: 1;
    box-shadow 0 2px 2px 0 rgba(0,0,0,0.16), 0 0 0 1px rgba(0,0,0,0.08)
    &--top 
      bottom 0
    &::after 
      content: " ";
      position: absolute;
      top 100%; 
      left: 10%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      opacity 0.4
      br-color  RGBA(0,0,0,0.4) transparent transparent transparent;
.close-popup 
  position: absolute 
  top 0
  right 0
  cursor pointer
  bg rgba(0,0,0,.4)
  color white 
  d flex 
  ai center 
  z-index 5
  jc center  
  w 30px 
  fs: 30px
  fd none
  h 30px    

.mask-img 
  position: absolute 
  top 0
  z-index 2
  left 0
  width: 100%
  height: 100% 
  opacity 0.7
  background: linear-gradient(45deg, $c.red--dark, $c.blue--dark)
  display flex 
  justify-content: center 
  align-items: center
  &__item
      width: 50px !important
      height: 50px !important
      display block !important  

.video-bnts
  d flex
  flex-wrap wrap 
  w 100%
  +media(from('md'))
    flex-wrap nowrap

.red-error
  color red
  color #57575a
  font-size 13px
  font-weight 700
  left 10px
  tr 0.5s
  z-index 1
  pointer-events: none
  width: calc(100% - 25px)
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  ta left
  d block
  ta left
  w 100%
  color: #da251c
  d block  
  mt 10px
  mb -10px
  pl 8px
  w 100%

