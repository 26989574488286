.col
  w 100%
  +media(from('md'))
    pa 0 8px
  &--align-end
    ai flex-end
    d flex
    mt 20px
  &--sm2-6
    +media(from('md'))
      w 50%
  &--sm1-6
    +media(from('sm'))
      w 50%
  &--sm2-4
    +media(from('md'))
      w 33.33%      
      .button
        &__wrapper
          w 100%  
  &--md-2
    +media(from('xl'))
      w 20%
  &--md-3
    +media(from('xl'))
      w 25%
  &--md-4
    +media(from('xl'))
      w 33%
  &--sm2-7
    +media(from('md'))
      w 66.66%
  &--sm2-8
    +media(from('md'))
      w 75%
  &--xl-9
    +media(from('md'))
      w 16.67%    
  &--xl-12
    +media(from('xl'))
      w 12.5%    
  &--xl-6
    +media(from('xl'))
      w 50%    
  &--xl-4
    +media(from('xl'))
      w 75% 
  &--xl-1
    +media(from('xl'))
      w 100%    
  &--local-flex
    pa 0 !important
    +media(from('md'))
      d flex
  &--no-pa 
    pa 0 !important
  // &--pr-0
  //   +media(from('md'))
  //     pr 0        
.row
  d flex
  jc space-between
  flex-wrap wrap
  w 100%
  &--around 
    jc space-around
    +media(from('fhd'))
      jc flex-start