@b alert
  $cw = 4sp
  $types = ( \
    ('error' $c.red) \
    ('info' $c.blue) \
    ('warning' $c.orange) \
    ('success' $c.green) \
  )

  d block
  pa 2sp 3sp
  pr $cw
  ma 1sp 0
  pos relative

  br: 1px solid $c.relief--dark
  br-radius 3px
  overflow hidden

  @m
    for $t in $types
      &t-{$t[0]}
        bg-color $t[1]
        br none

        color #fff

        @bn
          @e
            &close
              bg: #0001
              color #fff

              &:hover
                bg: #0002
                color #fff

  @e
    &close
      pos absolute
      d flex
      top 0
      right 0
      h 100%
      align-items center
      justify-content center
      ta center
      w $cw

      fs 5ty
      fd none
      font-family Times New Roman
      color: $c.asphalt
      bg: $c.relief--dark

      &:hover
        color: $c.red
