.cards
  d flex
  jc flex-start
  f-dir column
  ai flex-end
  &__wrapper
    d flex
    jc space-around
    flex-wrap wrap
    w 100%
    pb 10px
    +media(from('sm'))
      w 100%
    +media(from('md'))
      jc space-around
    +media(from('xl'))
      jc space-between
    for x in 1..3
      .card:nth-child({x})
        transition-delay: 0.5s * x  


.card
  pos relative
  w 49%
  min-h 192px
  br-radius 5px
  bg-color: $c.blue--dark
  color #fff
  ft uppercase
  mt 20px
  
  +media(from('sm'))
    ml 0
  &__content
    d flex
    f-dir row
    pa 30px 20px 40px 20px
    jc space-between
    pos relative
    &:before
      content ""
      pos absolute
      left 50%
      top 30px
      transform translateX(-50%,)
      w 1px
      h 57%
      bg: $c.lavender
  &__title
    fs 20px
    fw 700
    ma 0
    br-bottom: 1px solid $c.lavender
    pa 10px 0
.cash
  d flex
  f-dir column
  jc space-between
  ta left
  w 50%
  +media(from('x;'))
    w 32%
  & + &
    pl 20px
  &__subtitle
    fs 12px
    fw 400
  &__price
    fs 15px
    fw 700
    lh 40px
    word-wrap  break-word
    max-w 112px
    +media(from('md'))
      fs 20px
    +media(from('xl'))
      fs 35px
    &--small
      fs 13px
      h 100%
      lh 25px
      d flex
      ai center    
      +media(from('md'))
        fs 20px
      +media(from('xl'))
        fs 25px

.list-enter {
    transform: translate(0, -100%);
    opacity: 0;
}

.list-enter-active {
    transition: all .3s;
}

.list-leave {    
    
}

.list-leave-active {
    transform: translate(0, 100%);
    opacity: 0;
    transition: all .3s;
}
.list-move {
    transition: transform .3s;
}