.flat-rate-calc-view
  pos: relative

.flat-rate-btns
  pos: relative
  height 70px

.smooth-dnd-draggable-wrapper
  pb 12px

.button-wrapper
  &--flex-wrap
    flex-wrap: wrap
    justify-content: center
    .button
      mb: 10px
      ml: 10px
  d: flex
  mb: 20px
  .button + .button
    ml: 10px
    mb: 10px
