@keyframes shake {
  0%, 100% {
    transform: translateX(0);
  }
  10%, 30%, 50%, 70%, 90% {
    transform: translateX(-5px);
  }
  20%, 40%, 60%, 80% {
    transform: translateX(5px);
  }
}

.sms-list-trigger
  width 40px;
  height 40px;
  br-radius: 50%
  background-color: $c.asphalt;
  d: flex
  ai: center
  jc: center
  color: $c.white
  pos: relative
  transition: opacity 0.3s
  position fixed
  right: 30px
  bottom: 30px
  &__
    &button
      color: $c.white
      fs: 20px
    &shadow
      position: absolute;
      top: -10px;
      left: -10px;
      height: calc(100% + 20px);
      width: calc(100% + 20px);
      border-radius: 50%;
      // transform: translate(-50%, -50%);
      background: $c.asphalt;
      opacity: .2;
    &count
      w: 8px
      height 8px
      d: flex
      jc: center
      ai: center
      text-decoration: none
      position: absolute
      top: 0
      right: 0
      transform: translate(50%, -50%)
      bg: $c.yellow
      color black
      h: 20px
      min-w: 20px 
      w: auto
      fw 700
      br-radius 50%

  &:hover
    opacity 0.8
    cursor pointer
    transition: opacity 0.3s
  &.is-active
    animation: shake 2s infinite;
.chat
  position: fixed;
  bottom: 80px;
  right: 30px;
  // transform: translate(-50%, -50%);
  width: 300px;
  height: 80vh;
  max-height: 500px;
  z-index: 2;
  overflow: hidden;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.5);
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  &--
    &page
      position: unset;
      width: 100%
      +media(from('lg'))
        width: 50%;
      box-shadow: unset;
      background: rgba(200, 211, 220, 0.4);
    &adaptive
      width: calc(100vw - (48px + 0.5rem))
      +media(from('md'))
        width: 100%
      +media(from('lg'))
        width: 50%;
.chat-messages
  flex: 1 1 auto;
  color: rgba(255, 255, 255, 0.5);
  overflow: hidden;
  position: relative;
  width: 100%;
.chat-messages-content
  position: absolute;
  top: 0;
  left: 0;
  height: 101%;
  width: 100%;

.chat-records-list
  width: calc(100vw - (48px + 0.5rem))
  +media(from('md'))
    width: 100%
  +media(from('lg'))
    width: calc(100% - 20px)
    margin-right: 20px
  &__
    &record
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap
      margin-bottom: 12px
    &element
      &:not(:last-child)
        margin-right: 8px
      &--
        &file
          audio
            height: 35px
    &title
      font-weight: 600
      margin: 8px 8px 4px

.list__name
  margin-top: 12px
  margin-left: -8px
  padding: 8px 8px
  bg: rgba(200,211,220,0.13)
  border-radius: 3px
  width: calc(100vw - (48px + 0.5rem))
  +media(from('md'))
    width: 100%
  +media(from('lg'))
    width: 50%;
  font-size: 14px

.chat-box
  position: relative;
  overflow: hidden;
  height: 100%;
  max-width: 100%;
  outline: 0;
  direction: ltr;
  padding: 0 10px;
  overflow: auto
.chat-container
  overflow: hidden;
  width: auto;
  height: auto;
  pa: 10px 0
.chat-overlay
  w: 100%
  h: 100%
  position: fixed;
  left: 0 
  top: 0
.sms-item
  clear: both;
  padding: 6px 10px 7px;
  background: rgba(0, 0, 0, 0.5);
  margin: 8px 0;
  font-size: 13px;
  line-height: 1.4;
  margin-left: 35px;
  position: relative;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  position relative
  float: right;
  color: #fff;
  text-align: right;
  background: linear-gradient(120deg, $c.asphalt, #257287);
  border-radius: 10px 10px 0 10px;
  mb: 22px
  &.is-left
    float: left;
    border-radius: 10px 10px 10px 0;
    background: rgba(0, 0, 0, 0.3)
    ml: 0
    mr: 35px
    .sms-item__time
      left: 0
      right: initial  
    &:before
      left: 0;
      border-top: 6px solid rgba(0, 0, 0, 0.3)
      border-right: 7px solid transparent;
      border-left: none
      right: initial
  &:before
    content: "";
    left: auto;
    right: 0;
    border-right: none;
    border-left: 5px solid transparent;
    border-top: 4px solid #257287;
    bottom: -4px;
    position: absolute;
  &__text
    pa: 0 
    ma: 0 
  &__time
    position: absolute;
    bottom: -18px;
    font-size: 11px;
    color: rgba(255, 255, 255, 1);
    right: 0;
    pa:  0 
    ma: 0 
    white-space: nowrap;
    &--
      &page
        color: #3d4a64;
  &--
    &page
      max-width: 100%
      +media(from('md'))
        max-width: 60%
      &.is-left
        background: #129fdd;
        .sms-item__text
          a
            color: #fff;
        &:before
          border-top: 4px solid #129fdd;
      .sms-item__text
        word-wrap: break-word

.message-box
  flex: 0 1 40px;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  padding: 10px;
  position: relative;
  &--
    &page
      background: rgba(200, 211, 220, 0.4);
.message-box .message-input
  background: none;
  border: none;
  outline: none !important;
  resize: none;
  color: rgba(255, 255, 255, 1);
  font-size: 11px;
  height: 70px;
  margin: 0;
  padding-right: 20px;
  width: 265px;
  &--
    &page
      width: 100%
      color: #3d4a64
.message-box .message-submit {
  position: absolute;
  z-index: 1;
  top: 9px;
  right: 10px;
  color: #fff;
  border: none;
  background: $c.asphalt;
  font-size: 10px;
  text-transform: uppercase;
  line-height: 1;
  padding: 6px 10px;
  border-radius: 10px;
  outline: none !important;
  transition: background 0.2s ease;
}