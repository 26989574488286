.frame-tabs
  &__wrapper
    d none
    w 100%
    +media(from('xl'))
      d block
    section + section
      pa 0
  &__content
    d flex
    flex-wrap wrap
    w 100%
    list-style: none
    ma 0
    pa 0
    +media(from('xl'))
      jc space-between
    // h 100%
    // .VueCarousel-slide
    //   flex-basis 100%
    //   +media(from('sm'))
    //     flex-basis 50%
    //   +media(from('md'))
    //     flex-basis 33.33%
    //   +media(from('xl'))
    //     flex-basis 20%
  &__pane 
    w 100%
    
  &__link
    text-decoration none
    color #fff
    d flex
    ai center
    fs 14px
    w auto
    h 38px
    
    word-wrap  break-word
    pl 20px
    pr 10px
  &__name
    word-wrap  break-word
    overflow hidden
    w 72%
  &__toggler
    pos relative
    w auto
    bg: $c.blue--dark
    br-radius 5px 5px 0 0
    d flex
    ai center
    h 38px
    align-self flex-start
    word-wrap  break-word
    &.is-active
      bg: $c.lavender
      .badge
        bg: $c.blue--dark
  &__list
    list-style none
    pa 0
    d flex
    f-dir column
    jc flex-start
    br-radius 5px 5px 0 0
    bg: $c.blue--dark
    min-h 41px
    pos relative
    ma 0
    mt 30px
    flex-wrap: wrap
    f-dir row
    &:after
      content ''
      bg: $c.lavender
      h 4px
      w 100%
      pos absolute
      bottom 0
      left 50%
      transform translateX(-50%)
    // +media(from('lg'))
.check
  &__wrapper
    d flex
    flex-wrap wrap
.frame-checkbox
  pos relative

  d flex
  &--radio
    .frame-checkbox__item
      br-radius 50%
      &:after
        bg: $c.lavender
        h 12px
        w 12px
        br-radius 50%
        br 0
  input
    d none !important 
  input:checked ~ .box__item
    &:after
      d block
  &__heading
    cursor pointer
    d flex
    f-dir column
    pl 45px
  .box
    &__item
      pos absolute
      top 0
      left 0
      h 30px
      w 30px
      bg: $c.blue--verylight
      d block
      &:after
        content ''
        pos absolute
        br: 3px solid $c.lavender
        br-top none
        br-right none
        w 9px
        h 5px
        transform translate(-50%, -50%) rotate(-45deg)
        left 50%
        top 50%
        d none
.badge
  d block
  bg: $c.lavender
  color #fff
  fs 11px
  ta center
  lh 22px
  fw 400
  h 22px
  w: 22px
  br-radius 9999px
  ml 10px
  // pos absolute
  // top 50%
  // transform: translateY(-50%)
  // right 15px
  // min-w 22px
  w: auto
  transform none
  pa: 0 6px
  white-space nowrap
  &--dropdown  
    right auto
    pos relative
    bg: $c.blue--dark        
    