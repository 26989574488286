@b history
  &
    @extends $view

  @e
    $offset = 3sp

    &list
      mv 4sp
      pa 0

      list-style none

    &item
      d block
      mb 4sp


@b history-card
  pa 3sp

  br: 1px solid $c.relief--dark
  br-radius 3px

  @e
    &header
      pb 3sp
      mb 3sp
      d flex
      align-items center

      br-bottom: 1px solid $c.relief--dark

    &user
      flex-grow 1

      & .user-bar
        $s = 30px

        @e
          &avatar
            wf $s
            h @w

          &username
            h $s
            fh @h
            fs 13px


    &user
    &date
      fs 13px
      ft uppercase
      fw 400

