@font-face
    font-family 'Montserrat'
    src url('/static/fonts/montserrat/black.eot')
    src url('/static/fonts/montserrat/black.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/montserrat/black.woff2') format('woff2'),
        url('/static/fonts/montserrat/black.woff') format('woff'),
        url('/static/fonts/montserrat/black.ttf') format('truetype'),
        url('/static/fonts/montserrat/black.svg#montserratblack') format('svg')

    font-weight 900
    font-style normal


@font-face
    font-family 'Montserrat'
    src url('/static/fonts/montserrat/light.eot')
    src url('/static/fonts/montserrat/light.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/montserrat/light.woff2') format('woff2'),
        url('/static/fonts/montserrat/light.woff') format('woff'),
        url('/static/fonts/montserrat/light.ttf') format('truetype'),
        url('/static/fonts/montserrat/light.svg#montserratlight') format('svg')

    font-weight 300
    font-style normal
@font-face 
    font-family Material Design Icons
    font-weight 500
    font-display swap
    src url('/static/fonts/materialdesignicons-webfont.woff2'),
        url('/static/fonts/materialdesignicons-webfont.woff'),
        url('/static/fonts/materialdesignicons-webfont.ttf'),
        url('/static/fonts/materialdesignicons-webfont.eot')