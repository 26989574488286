.quote-form
  +media(from('md'))
    .form
      &__cell
        &--s-1 
          w 8%
        &--s-2
          w 16% 
.fixed-card
  max-w 250px
  mb 20px
  br: 1px solid $c.relief--dark
.progress 
  display block
  margin 0 auto
  // overflow hidden
  // transform rotate(-90deg) rotateX(180deg)
  w 100%
  h 100%
  max-w 100%
  max-h 100%
  & circle 
    stroke-dashoffset 0
    transition stroke-dashoffset 2s ease
    stroke: $c.blue--dark
    stroke-width 15px
  &__bar
    stroke: $c.blue !important
.hidden
  d none    
.percent-card
  h 100%
  pa 10px
  d flex 
  f-dir column
  // ai center 
  jc space-between 
  &--is-vertical
    d flex 
    f-dir row 
    flex-wrap wrap
    ai center
    .percent-card 
      &__head 
        f-dir column
      &__title
        w 100%   
      &__body
        w 53%
    .info-bar
      f-dir column
      &__part 
        pb 10px
        f-dir row
        mr 0
      &__title 
        mr 5px  
  &__title 
    ft uppercase 
    mb 0
    lh 1
  &__body
    w 53%
    h 60%
    ma 0 auto
    position relative
    .chart 
      pos relative
      h 100%
    figure 
      h 100%
      ma 0 auto
      max-w 100%
    canvas 
      w auto
      h 70%
      max-h 100%
      max-w 100%
      ma 0 auto 
      d block 
    span   
      position absolute
      top 0%
      height 70%
      left 50%
      font-size 15px
      d flex
      ai center 
      z-index 9999
      fs 20px 
      fw 700
      jc center
      transform translateX(-50%)
      content attr(data-percent) " %"
.heatmap
  h 100%      
.info-bar   
  d flex
  flex-wrap wrap 
  jc space-around
  &__part
    mr 10px 
    d flex 
    f-dir column
    ai center 
    jc center 
  &__title 
    fs 13px
    ma 0 
    fw 700
    lh 1

.mini-card
  h 100%
  pa 10px
  d flex
  ai flex-start 
  f-dir column
  jc space-between
  &__title 
    ft uppercase 
    mb 0
    lh 1
    align-self flex-start
  &__body 
    d flex 
    ai center 
    jc space-between
    w 100%
  &__icon
    fs 40px
    fw 500
    color: $c.blue
  &__value 
    fs 25px 
    fw 700  
    ma 0

.grid 
  d grid 
  grid-gap 10px
  h calc(100vh - 165px)
  grid-template-columns repeat(6, auto)
  grid-template-rows repeat(3, 33%)
  &__item
    grid-column span 2
    br: 2px solid $c.asphalt
.vue-map-container
  h 300px !important
  w 100%
  +media(from('md'))
    h 100% !important
.vue-map
 h 100%    

.vue-grid-item
  br: 1px solid $c.relief--dark

.chart-card 

  pos relative
  height 80%
  width 100%
  pa 10px
  &--fixed 
    h auto
    w auto
  &__row 
    h 100%  
    &.tiny
      d flex
      ai center 
      jc flex-start
  &__body
    height 100%
    pos relative
    height 100%
    width 100%
    &.tiny
      w 60%
  &__title 
    ft uppercase 
    mb 0
    lh 1

// percent-circle($diameter, $stroke-width, $fg-color, $bg-color)
//   $percent = attr(data-percent)
//   $pi = 3.14159
//   $circumference = $diameter * $pi
//   $grey-color = lighten(desaturate($fg-color, 80%), 50%)
//   // Make sure grey isn't white
//   if $grey-color == white
//     $grey-color = #efefef

//   svg:before
//     content: $grey-color
//     display: block
//   width: $diameter
//   height: $diameter
//   border-radius: 50%
//   overflow: hidden
//   position: relative
//   svg
//     width: $diameter
//     height: $diameter
//     //the dash starts at 3 o'clock so we need to rotate it back
//     transform: rotate(-90deg)
//     .percent-circle-inner
//       fill: $grey-color
//       stroke: $fg-color
//       stroke-width: ($stroke-width * 2)
//       // this prop will get updated in js
//       stroke-dasharray: 0 $circumference
//       transition: stroke-dasharray 0.6s ease-out
//   &:after
//     //write the percetage in straight from the data attribute
//     content: $percent "%"
//     display: block
//     width: ($diameter - $stroke-width * 2)
//     height: ($diameter - $stroke-width * 2)
//     background: $bg-color
//     position: absolute
//     top: $stroke-width
//     left: $stroke-width
//     border-radius: 50%
//     color: $fg-color
//     text-align: center
//     line-height: ($diameter - $stroke-width * 2)
//     font-size: ($diameter * 0.2)
// .pc2
//   percent-circle(130px, 15px, #ff9900, white)

// .hidden
//   display: none

.dashboard-label
  pos absolute 
  top 35% 
  right 10px 
  bg white 
  box-shadow 3px 3px 10px rgba(0,0,0,.5)
  color: $c.asphalt
  d flex
  pa 5px 
  ai center 
  jc center 
  fw 700

.map-wrap
  w 100%
  h 100%
  pos relative
  &__icon 
    pos absolute 
    bottom 0px 
    left 0px 
    bg white 
    box-shadow 3px 3px 10px rgba(0,0,0,.5)
    color: $c.asphalt
    d flex
    pa 10px
    fs 20px 
    ai center 
    jc center 
    fw 700
    &:hover 
      color: $c.blue 


.additional-chart-info
  &__item
    mr 10px 
    d flex 
    f-dir row
    ai center 
    jc flex-start 
    mb 10px
  &__title 
    fs 13px
    ma 0 
    fw 700
    lh 1
  &__text  
    fs 13px
    ma 0 
    fw 500
    ml 5px
    lh 1


.stars-cards
  pa 10px 
  &__title 
    fw 700
    ft uppercase 
    mb 0
    lh 1
    align-self flex-start
  &__line 
    d flex 
    ai center 
    jc flex-start
    f-dir column 
    +media(from('xl'))
      f-dir row
      mt 10px
  .vue-star-rating  
    pa 0 5px 
  &__text 
    fw 700  
    ma 0
    +media(from('xl'))
      ma initial