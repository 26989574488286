html
  bg #fff


body
  bg: $c.relief
  // min-w 1280px
  color: $c.asphalt--dark

  &.is-unscrollable
    // position fixed
    overflow hidden
    h 100%
    w 100%

a
label
button
input[type="button"]
input[type="submit"]
input[type="reset"]
  cursor pointer

a
  d inline
  fd none
  fs inherit
  fd underline

  color: $c.blue

  &:hover
    fd none

  .t-&
    @m
      &lineless
        fd none

img
iframe
video
table
  max-width 100%


div
button
input
  outline none


ul
  .t-&
    @m
      &unstyled
        d block
        list-style none
        pa 0
        ma 0


hr
  border-color: $c.gray


[v-cloak]
  d none


h1, h2, h3, h4, h5, h6
  &:first-child
    mt 0

  &:last-child
    mb 0

.t-h
  @m
    &upper
      ft uppercase

.is-clear 
  pa: 0
  ma: 0

* {
  //  outline: 1px solid red !important;
}