// $calendar_bg = #fff
// $calendar_br_color = #fff

// $months_color = $c.asphalt
// $months_bg = transparent

// $weekdays_bg = transparent
// $weekdays_color = $c.asphalt

// $day_text_color = $c.asphalt
// $day_hover_bg_color = $c.gray
// $day_hover_fg_color = $c.asphalt
// $day_br_radius = 3px // 150px

// $disabled_day_color = $c.gray

// $today_color = $c.asphalt
// $today_fg_color = #fff

// $selected_day_bg =  $c.blue
// $selected_day_color = #fff

// $br_radius = 0

// $arrow_size = 2sp


// .flatpickr-input
//   cursor pointer
//   z-index 1

// .flatpickr-mobileInput
//   opacity 0
//   visibility hidden
//   pos absolute
//   w 0
//   h 0
//   box-sizing border-box
//   pa 0

// .flatpickr-calendar
//   bg $calendar_bg
//   d none
//   ta center
//   pa 0

//   unless $br-calendar-only is defined
//     br 1px solid $calendar_br_color

//   fs 90%
//   br-radius $br_radius
//   pos absolute
//   max-w 256px
//   min-w 200px

//   &.is-only-month .flatpickr-rContainer
//     d none

//   &.open
//     opacity 1
//     z-index 99999
//     d inline-block

//   &.inline
//     max-w 288px
//     d table
//     mt 2px
//     pos relative

//   &.hasWeeks
//     max-w 300px

//   &:before, &:after
//     pos absolute
//     d block
//     pointer-events none
//     br solid transparent
//     content ''
//     h 0
//     w 0
//     left 22px

//   &:before
//     br-width $arrow_size
//     margin 0 -5px

//   &:after
//     br-width $arrow_size - 1px
//     margin 0 -4px

//   &.arrowTop
//     &:before, &:after
//       bottom 100%
//     &:before
//       br-bottom-color $calendar_br_color
//     &:after
//       if $br-calendar-only is defined
//         br-bottom-color $months_bg
//       else
//         br-bottom-color $calendar_bg

//   &.arrowBottom
//     &:before, &:after
//       top 100%
//     &:before
//       br-top-color $calendar_br_color
//     &:after
//       if $br-calendar-only is defined
//         br-top-color $months_bg
//       else
//         br-top-color $calendar_bg


// .flatpickr-month
//   if $br-calendar-only is defined
//     br-radius $br_radius $br_radius 0 0
//   bg $months_bg
//   color $months_color
//   fill $months_color
//   pa 4px 0 0
//   ta center
//   user-select none
//   pos relative



// .flatpickr-prev-month, .flatpickr-next-month
//   text-decoration none
//   cursor pointer

//   i
//     pos relative

//   svg
//     w 14px
//     pos absolute
//     top 9px
//     path
//       fill inherit

//     &:hover
//       fill $today_color

//   &:hover
//     color $today_color

// .flatpickr-prev-month svg
//   left 5px

// .flatpickr-next-month svg
//   right 5px

// .flatpickr-current-month
//   fs 135%
//   font-weight 300
//   color inherit
//   pos relative
//   d inline-block

//   .cur_month
//     font-weight 700
//     color inherit

//   .cur_year
//     bg transparent
//     box-sizing border-box
//     color inherit
//     cursor default
//     pa 0
//     margin 0
//     w 3.6em
//     d inline
//     fs inherit
//     font-weight 300
//     fh inherit
//     h initial
//     br 0

//     &:hover
//       bg rgba(0,0,0,0.05)

// .flatpickr-weekdays
//   bg $weekdays_bg
//   ta center
//   overflow hidden

// .flatpickr-days
//   pt 1px
//   outline 0

//   if $br-calendar-only is defined
//     br 1px solid $calendar_br_color
//     br-top 0
//     br-bottom 0
//     br-radius 0 0 $br_radius $br_radius

// .flatpickr-day
//     bg none
//     br 1px solid transparent
//     br-radius $day_br_radius
//     box-sizing border-box
//     color $day_text_color
//     cursor pointer
//     d inline-block
//     font-weight 300
//     w (100% / 7)
//     h 35px
//     fh 35px
//     margin 0
//     ta center

//     &, &.prevMonthDay, &.nextMonthDay
//       &:hover, &:focus
//         cursor pointer
//         outline 0
//         bg $day_hover_bg_color
//         color $day_hover_fg_color
//         br-color $day_hover_bg_color

//     &.today
//       br-color $today_color

//       &:hover, &:focus
//         br-color $today_color
//         bg $today_color
//         if $today_fg_color is defined
//           color $today_fg_color
//         else
//           color white

//     &.selected, &.selected:hover, &.selected:focus
//       bg $selected_day_bg

//       if $selected_day_color is defined
//         color $selected_day_color
//       else
//         color white

//       br-color $selected_day_bg

//     &.disabled, &.disabled:hover, &.prevMonthDay, &.nextMonthDay
//       if $disabled_day_color is defined
//         color $disabled_day_color
//       else
//         color alpha($day_text_color, 0.3)
//       bg transparent
//       br-color transparent
//       cursor default



// span.flatpickr-weekday
//   cursor default
//   fs 90%

//   if $weekdays_color is defined
//     color alpha($weekdays_color, 0.54)
//   else
//     color alpha(black, 0.54)

//   h 24px
//   fh 24px
//   margin 0
//   bg $months_bg
//   ta center
//   d block
//   float left
//   w 14.28%
//   font-weight bold


// .flatpickr-weekwrapper
//   d inline-block
//   float left

//   .flatpickr-weeks
//     pa 0 0.25rem
//     if $br-calendar-only is defined
//       br-left 1px solid $calendar_br_color
//       br-bottom 1px solid $calendar_br_color
//     br-right 1px solid $calendar_br_color

//   .flatpickr-weekday
//     float none
//     w 100%

//   span.flatpickr-day
//     d block
//     w 100%

// .flatpickr-rContainer
//   d inline-block
//   max-w 249px
//   if $br-calendar-only is defined
//     br-bottom 1px solid $calendar_br_color

// .flatpickr-time
//   overflow auto
//   ta center
//   br-top 0
//   outline 0
//   d block
//   d flex

//   input
//     -moz-appearance textfield
//     bg transparent
//     box-shadow none
//     br 0
//     br-radius 0
//     d inline-block
//     flex 1
//     w 33%
//     min-w 33%
//     ta center
//     margin 0
//     pa 0
//     h 38px
//     fh 38px
//     cursor pointer
//     font-weight bold
//     color $day_text_color

//     &.flatpickr-minute
//       w 26%
//       font-weight 300

//     &.flatpickr-second
//       font-weight 300

//     &:focus
//       outline 0
//       br 0

//     &:hover, &:focus
//       -moz-appearance: number-input
//       bg lighten($day_hover_bg_color, 4)
//       color $day_hover_fg_color

//   &.has-seconds
//     input[type='number']
//       w 25%
//       min-w 25%

// .hasWeeks, .hasTime
//   .flatpickr-days
//     br-bottom 0
//     br-bottom-right-radius 0
//     br-bottom-left-radius 0

// .hasWeeks .flatpickr-days
//   br-left 0

// .flatpickr-rContainer + .flatpickr-time
//   if $br-calendar-only is defined
//     br 1px solid $calendar_br_color

//   else
//     br-top 1px solid $calendar_br_color

// .flatpickr-time-separator, .flatpickr-am-pm
//   h 38px
//   d inline-block
//   fh 38px
//   color $day_text_color

// .flatpickr-am-pm
//   outline 0
//   w 21%
//   pa 0 2%
//   cursor pointer
//   ta left
//   font-weight 300
//   ta center

//   &:hover, &:focus
//     bg lighten($day_hover_bg_color, 4)
//     color $day_hover_fg_color


// @media all and (-ms-high-contrast: none)
//   .flatpickr-month
//     pa 0


// $calendar_bg = #ffffff
// $calendar_border_color = #e6e6e6

// $months_color = alpha(black, 0.9)

// $months_bg = transparent

// $weekdays_bg = transparent

// $day_text_color = #393939
// $day_hover_bg_color = #e6e6e6

// $today_color = #f99595
// $selected_day_bg = #3da3f3

$calendar_bg = #fff
$calendar_border_color = #fff

$months_color = $c.asphalt
$months_bg = transparent

$weekdays_bg = transparent
$weekdays_color = $c.asphalt

$day_text_color = $c.asphalt
$day_hover_bg_color = $c.gray
$day_hover_fg_color = $c.asphalt
$day_br_radius = 3px // 150px

$disabled_day_color = $c.gray

$today_color = $c.asphalt
$today_fg_color = #fff

$selected_day_bg =  $c.blue
$selected_day_color = #fff

$br_radius = 0

$arrow_size = 2sp

.flatpickr-input
  cursor pointer
  z-index 1

.flatpickr-mobileInput
  opacity 0
  visibility hidden
  position absolute
  width 0
  height 0
  box-sizing border-box
  padding 0

.flatpickr-calendar
  bg $calendar_bg
  display none
  text-align center
  padding 0
  min-w 230px

  unless $border-calendar-only is defined
    border 1px solid $calendar_border_color

  font-size 13px
  border-radius 3px
  position absolute

  &.is-only-month .flatpickr-rContainer
    d none

  &.open
    opacity 1
    z-index 99999
    display inline-block


  &.inline
    display inline-block
    margin-top 2px
    position relative

  &.static
    position relative

    &.open
      display block

  &.hasWeeks
    max-width 320px

  &:before, &:after
    pos absolute
    d block
    pointer-events none
    br solid transparent
    content ''
    h 0
    w 0
    left 22px

  &:before
    br-width $arrow_size
    margin 0 -5px

  &:after
    br-width $arrow_size - 1px
    margin 0 -4px

  &.arrowTop
    &:before, &:after
      bottom 100%
    &:before
      br-bottom-color $calendar_border_color
    &:after
      if $br-calendar-only is defined
        br-bottom-color $months_bg
      else
        br-bottom-color $calendar_bg

  &.arrowBottom
    &:before, &:after
      top 100%
    &:before
      br-top-color $calendar_border_color
    &:after
      if $br-calendar-only is defined
        br-top-color $months_bg
      else
        br-top-color $calendar_bg

  // &:before, &:after
  //   position absolute
  //   display block
  //   pointer-events none
  //   border solid transparent
  //   content ''
  //   height 0
  //   width 0
  //   left 22px

  // &:before
  //   border-width 5px
  //   margin 0 -5px

  // &:after
  //   border-width 4px
  //   margin 0 -4px

  // &.arrowTop
  //   &:before, &:after
  //     bottom 100%
  //   &:before
  //     border-bottom-color $calendar_border_color
  //   &:after
  //     if $border-calendar-only is defined
  //       border-bottom-color $months_bg
  //     else
  //       border-bottom-color $calendar_bg

  // &.arrowBottom
  //   &:before, &:after
  //     top 100%
  //   &:before
  //     border-top-color $calendar_border_color
  //   &:after
  //     if $border-calendar-only is defined
  //       border-top-color $months_bg
  //     else
  //       border-top-color $calendar_bg


.flatpickr-month
  if $border-calendar-only is defined
    border-radius 3px 3px 0 0
  bg $months_bg
  color $months_color
  fill $months_color
  padding 4px 0 0
  padding unquote('0px\9')
  text-align center
  position relative
  w 80%
  ma 0 auto


.flatpickr-prev-month, .flatpickr-next-month
  text-decoration none
  cursor pointer

  i
    position relative

  svg
    width 14px
    position absolute
    top 8px
    top unquote('0px\9')

    path
      fill inherit

    &:hover
      fill $today_color

  &:hover
    color $today_color

.flatpickr-prev-month svg
  left 5px

.flatpickr-next-month svg
  right 5px

.flatpickr-current-month
  font-size 135%
  font-weight 300
  color inherit
  position relative
  display inline-block
  line-height 1
  ml 4sp
  mr 3sp

  .cur-month
    font-weight 700
    color inherit

  .cur-year
    bg transparent
    box-sizing border-box
    color inherit
    cursor default
    padding 0
    margin 0
    width 3.2em
    display inline
    font-size inherit
    font-weight 300
    line-height inherit
    height initial
    border 0
    border-radius: 0;
    vertical-align: initial;

    &:hover
      bg rgba(0,0,0,0.05)

.flatpickr-weekdays
  bg $weekdays_bg
  text-align center
  overflow hidden

.flatpickr-days
  padding-top 1px
  outline 0
  text-align left
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;

  if $border-calendar-only is defined
    border 1px solid $calendar_border_color
    border-top 0
    border-bottom 0
    border-radius 0 0 3px 3px

.flatpickr-day
  bg none
  border 1px solid transparent
  br-radius $day_br_radius
  box-sizing border-box
  color $day_text_color
  cursor pointer
  display inline-block
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;

  font-weight 300
  width 14.28571%
  height 35px
  line-height 35px
  margin 0
  text-align center
  transition 50ms all ease-out

  &, &.prevMonthDay, &.nextMonthDay
    &.inRange, &:hover, &:focus
      cursor pointer
      outline 0
      bg $day_hover_bg_color
      border-color $day_hover_bg_color

  &.today
    border-color $today_color

    &:hover, &:focus
      border-color $today_color
      bg $today_color
      if $today_fg_color is defined
        color $today_fg_color
      else
        color white

  &.selected
    &, &:focus, &:hover, &.prevMonthDay, &.nextMonthDay
      bg $selected_day_bg

      if $selected_day_color is defined
        color $selected_day_color
      else
        color white

      border-color $selected_day_bg

  &.disabled, &.disabled:hover,
  &.prevMonthDay, &.nextMonthDay,
  &.notAllowed, &.notAllowed.prevMonthDay, &.notAllowed.nextMonthDay
    if $disabled_day_color is defined
      color $disabled_day_color
    else
      color alpha($day_text_color, 0.3)
    bg transparent
    if $disabled_border_color is defined
      border-color $disabled_border_color
    else
      border-color transparent
    cursor default



span.flatpickr-weekday
  cursor default
  font-size 90%

  if $weekdays_color is defined
    color alpha($weekdays_color, 0.54)
  else
    color alpha(black, 0.54)

  height 24px
  line-height 24px
  margin 0
  bg $months_bg
  text-align center
  display block
  float left
  width 14.28%
  font-weight bold


.flatpickr-weekwrapper
  display inline-block
  float left

  .flatpickr-weeks
    if $border-calendar-only is defined
      border-left 1px solid $calendar_border_color
    border-right 1px solid $calendar_border_color

  .flatpickr-weekday
    float none
    width 100%

  span.flatpickr-day
    display block
    width 100%
    margin-right 1rem

.flatpickr-innerContainer
  display block
  display flex
  if $border-calendar-only is defined
    border-bottom 1px solid $calendar_border_color

  &:after
    display inline-block
    content ""
    clear both

.flatpickr-rContainer
  display inline-block
  max-width 247px


.flatpickr-time
  overflow auto
  text-align center
  border-top 0
  outline 0
  display block
  display flex

  input
    -moz-appearance textfield
    bg transparent
    box-shadow none
    border 0
    border-radius 0
    display inline-block
    flex 1
    text-align center
    margin 0
    padding 0
    height 38px
    line-height 38px
    cursor pointer
    font-weight bold
    color $day_text_color

    &.flatpickr-minute, &.flatpickr-second
      font-weight 300

    &:focus
      outline 0
      border 0

    &:hover, &:focus
      -moz-appearance: number-input
      bg $day_hover_bg_color

  .flatpickr-time-separator, .flatpickr-am-pm
    height 38px
    display inline-block
    line-height 38px
    color $day_text_color

  .flatpickr-am-pm
    outline 0
    width 18%
    cursor pointer
    text-align center
    font-weight 300

    &:hover, &:focus
      bg lighten($day_hover_bg_color, 4)


.hasWeeks, .hasTime
  .flatpickr-days
    border-bottom 0
    border-bottom-right-radius 0
    border-bottom-left-radius 0

.hasWeeks .flatpickr-days
  border-left 0

.flatpickr-innerContainer + .flatpickr-time
  if $border-calendar-only is defined
    border 1px solid $calendar_border_color
    border-top 0

  else
    border-top 1px solid $calendar_border_color




@media all and (-ms-high-contrast: none)
  .flatpickr-month
    padding 0
    svg
      top 0 !important


span.flatpickr-day
  font-weight 400

.flatpickr-calendar
  pa 2sp
  box-shadow 0 10px 35px #0003


.numInputWrapper {
  position: relative;
  height: auto;
}
.numInputWrapper input,
.numInputWrapper span {
  display: inline-block;
}

.numInputWrapper input::-ms-clear {
  display: none;
}
.numInputWrapper input::-webkit-outer-spin-button,
.numInputWrapper input::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
}
.numInputWrapper span {
  position: absolute;
  right: 0;
  width: 14px;
  padding: 0 4px 0 2px;
  height: 50%;
  line-height: 50%;
  opacity: 0;
  cursor: pointer;
  border: 1px solid rgba(57,57,57,0.15);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.numInputWrapper span:hover {
  background: rgba(0,0,0,0.1);
}
.numInputWrapper span:active {
  background: rgba(0,0,0,0.2);
}
.numInputWrapper span:after {
  display: block;
  content: "";
  position: absolute;
}
.numInputWrapper span.arrowUp {
  top: 0;
  border-bottom: 0;
}
.numInputWrapper span.arrowUp:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid rgba(57,57,57,0.6);
  top: 26%;
}
.numInputWrapper span.arrowDown {
  top: 50%;
}
.numInputWrapper span.arrowDown:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(57,57,57,0.6);
  top: 40%;
}
.numInputWrapper span svg {
  width: inherit;
  height: auto;
}
.numInputWrapper span svg path {
  fill: rgba(0,0,0,0.5);
}
.numInputWrapper:hover {
  background: rgba(0,0,0,0.05);
}
.numInputWrapper:hover span {
  opacity: 1;
}