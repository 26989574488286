$view
  @e
    &section
      mv 3sp

      @m
        &first
          mt 0

        &last
          mb 0

    &header
      d flex
      align-items center
      @m
        &end
          jc flex-end
      @m
        &center
          jc center

    &footer
      d flex
      align-items center

    &title
      ma 0
      fs 17px
      fw 900
      ft uppercase

    &views
      pl 2sp

.view
  @extend $view
