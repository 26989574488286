tether-arrow(
    color = #fff,
    size = 2sp,
    prefix = 'tether'
    )
  $selector = replace('\.', '', selector('&'))

  // Centers and middles

  /.{$selector}-element-attached-bottom.{$selector}-element-attached-center .{prefix}-content
    margin-bottom: size

    &:before
      top: 100%
      left: 50%
      margin-left: 0 - size
      border-top-color: color
      border-bottom: 0
      triangle: size bottom color

  /.{$selector}-element-attached-top.{$selector}-element-attached-center .{prefix}-content
    margin-top: size

    &:before
      bottom: 100%
      left: 50%
      margin-left: 0 - size
      border-bottom-color: color
      border-top: 0
      triangle: size top color

  /.{$selector}-element-attached-right.{$selector}-element-attached-middle .{prefix}-content
    margin-right: size

    &:before
      left: 100%
      top: 50%
      margin-top: 0 - size
      border-left-color: color
      border-right: 0
      triangle: size right color

  /.{$selector}-element-attached-left.{$selector}-element-attached-middle .{prefix}-content
    margin-left: size

    &:before
      right: 100%
      top: 50%
      margin-top: 0 - size
      border-right-color: color
      border-left: 0
      triangle: size left color

  // Target middle/center, element corner

  /.{$selector}-element-attached-left.{$selector}-target-attached-center .{prefix}-content
    left: 0 - size * 2

  /.{$selector}-element-attached-right.{$selector}-target-attached-center .{prefix}-content
    left: size * 2

  /.{$selector}-element-attached-top.{$selector}-element-attached-left.{$selector}-target-attached-middle .{prefix}-content
    margin-top: size

    &:before
      bottom: 100%
      left: size
      border-bottom-color: color
      border-top: 0
      triangle: size top color

  /.{$selector}-element-attached-top.{$selector}-element-attached-right.{$selector}-target-attached-middle .{prefix}-content
    margin-top: size

    &:before
      bottom: 100%
      right: size
      border-bottom-color: color
      border-top: 0
      triangle: size top color

  /.{$selector}-element-attached-bottom.{$selector}-element-attached-left.{$selector}-target-attached-middle .{prefix}-content
    margin-bottom: size

    &:before
      top: 100%
      left: size
      border-top-color: color
      border-bottom: 0
      triangle: size bottom color

  /.{$selector}-element-attached-bottom.{$selector}-element-attached-right.{$selector}-target-attached-middle .{prefix}-content
    margin-bottom: size

    &:before
      top: 100%
      right: size
      border-top-color: color
      border-bottom: 0
      triangle: size bottom color


  // Top and bottom corners

  /.{$selector}-element-attached-top.{$selector}-element-attached-left.{$selector}-target-attached-bottom .{prefix}-content
    margin-top: size

    &:before
      bottom: 100%
      left: size
      border-bottom-color: color
      border-top: 0
      triangle: size top color

  /.{$selector}-element-attached-top.{$selector}-element-attached-right.{$selector}-target-attached-bottom .{prefix}-content
    margin-top: size

    &:before
      bottom: 100%
      right: size
      border-bottom-color: color
      border-top: 0
      triangle: size top color

  /.{$selector}-element-attached-bottom.{$selector}-element-attached-left.{$selector}-target-attached-top .{prefix}-content
    margin-bottom: size

    &:before
      top: 100%
      left: size
      border-top-color: color
      border-bottom: 0
      triangle: size bottom color

  /.{$selector}-element-attached-bottom.{$selector}-element-attached-right.{$selector}-target-attached-top .{prefix}-content
    margin-bottom: size

    &:before
      top: 100%
      right: size
      border-top-color: color
      border-bottom: 0
      triangle: size bottom color

  // Side corners

  /.{$selector}-element-attached-top.{$selector}-element-attached-right.{$selector}-target-attached-left .{prefix}-content
    margin-right: size

    &:before
      top: size
      left: 100%
      border-left-color: color
      border-right: 0
      triangle: size top color

  /.{$selector}-element-attached-top.{$selector}-element-attached-left.{$selector}-target-attached-right .{prefix}-content
    margin-left: size

    &:before
      top: size
      right: 100%
      border-right-color: color
      border-left: 0
      triangle: size top color

  /.{$selector}-element-attached-bottom.{$selector}-element-attached-right.{$selector}-target-attached-left .{prefix}-content
    margin-right: size

    &:before
      bottom: size
      left: 100%
      border-left-color: color
      border-right: 0
      triangle: size bottom color

  /.{$selector}-element-attached-bottom.{$selector}-element-attached-left.{$selector}-target-attached-right .{prefix}-content
    margin-left: size

    &:before
      bottom: size
      right: 100%
      border-right-color: color
      border-left: 0
      triangle: size bottom color


tether-drop(
    bg = #fff,
    arrow-size = 2sp,
    arrow-color = bg,
    prefix = 'tether',
    )

  tether-arrow(color:arrow-color, size: arrow-size, prefix:prefix)

  &-
    &element
      d none

      &.{prefix}-open
        d block

      & .{prefix}-content
        pos relative
        pa 2sp
        fw 300

        bg: bg

        &:before
          pos absolute
          content ''

    &content
      //


.ui-tooltip
  tether-drop(
      bg: $c.paper,
      arrow-size: 2sp,
      arrow-color: $c.paper,
      prefix: 'tooltip'
    )

  &-
    &element

      & .tooltip-content
        br-radius $border-radius
        box-shadow 0 1px 10px rgba(#000, .05), 0 5px 15px #0001

        white-space normal
        max-width 250px

    &target
      pos relative
      cursor help

      &:after
        $s = 1em

        pos absolute
        d block
        h $s * 1.6
        w @h
        top 0 - (@h / 2)
        right 0 - (@h / 2)

        // br-radius 99px

        font-family Verdana, Arial
        fs $s * .875
        fh @h
        fw 200
        ta center

        color #fff
        text-shadow: 0 1px 2px $c.highlight, 0 1px 2px $c.highlight, \
          0 1px 2px $c.highlight
        // bg: $c.highlight
        // color #fff
        // text-shadow: \
        //   0 1px 1px $c.highlight, \
        //   1px 0 1px $c.highlight, \
        //   1px 1px 1px $c.highlight, \
        //   -1px -1px 1px $c.highlight, \
        //   -1px 0 1px $c.highlight, \
        //   0 -1px 1px $c.highlight, \
        //   -1px 1px 1px $c.highlight, \
        //   1px -1px 1px $c.highlight

        content '?'



.ui-drop
  tether-drop(prefix: 'drop')

  &-
    &element
      & .drop-content
        box-shadow 0 10px 35px #0003



