.calculate
  w 100%
  &__wrapper
    d flex
    f-dir column
    ai center
    pt 15px
    w 100%
    +media(from('sm'))
      pt 30px
    +media(from('md'))
      pr 16px
    +media(from('xl'))
      pt 60px
.message
  d flex
  jc flex-start
  f-dir column
  ai flex-end
  pos relative
  // pb 190px
  // +media(from('sm'))
  //   pb 150px
  +media(from('md'))
    pa 50px 30px
    pb 0
  +media(from('xl'))
    pa 50px 100px
  &--cards
    mt 20px
    d block
    ai center
    +media(from('md'))
      pa 10px
    +media(from('xl'))
      ai flex-start
      pa 20px
  &__heading
    d flex
    f-dir column
    jc center
    +media(from('xl'))
      ta left
      .subtitle
        +media(from('xl'))
          pl 0
          ml 0
  &--border
    +media(from('md'))
      min-h 276px
      br-radius 5px
      br: 3px solid $c.blue--verylight
  &--calculate
    h 180px
    pa 25px 14px
    ma 20px 0
    box-shadow none
    pos relative
    br-radius 5px
    br: 3px solid $c.blue--verylight
    +media(from('md'))
      ma 30px 0
      min-h auto
      pa 25px 14px
    +media(from('lg'))
      pa 25px 14px
    +media(from('xl'))
      pa 50px 100px
    &:after
      content ""
      pos absolute
      width 0
      h 0
      
      br 10px solid #fff
      br-color transparent transparent #fff #fff
      box-shadow -3px 3px 0 0 rgb(241, 242, 246)
      bottom auto
      top -10px
      left 50%
      transform translateX(-50%) rotate(135deg)    
  &--xl-mt-100
    mt 100px    
  &__content
    w 100%
    d flex
    f-dir column
    mt 20px
    +media(from('md'))
      w 300px
      mt 0
    +media(from('lg'))
      w 380px
    +media(from('xl'))
      w 450px
  &__info
    mb 20px
  &__heading
    d flex
    align-self flex-end
    fs 22px
    fw 700
    ma 0
    color: $c.blue--dark
    lh 24px
    ft none
    jc center
    +media(from('lg'))
      fs 30px
      lh 38px
      ta left
      jc flex-start
  &__text
    p
      fs 20px
      lh 24px
      color: $c.blue--dark
      ma 0
      mt 20px
      fw 700
      +media(from('lg'))
        ta left
  &__image
    pos relative
    bottom 0
    align-self center
    h 256px
    mt 20px
    img
      d block
      h 100%
    +media(from('md'))
      mt 0
      pos absolute
      left 50px
    +media(from('lg'))
      h 364px
      img
        h 364px
    +media(from('xl'))
      left 100px
  
 .flip-container 
    perspective: 1000px;
    w 100%
    /* flip the pane when hovered */
    .flip-container.hover .flipper 
        transform: rotateX(180deg);
        
    // .flip-container, .front, .back 
    //     width: 320px;
    //     height: 480px;

    /* flip speed goes here */
    .flipper 
      transition: 0.6s;
      transform-style: preserve-3d;
      position: relative;
  

    /* hide back of pane during swap */
    .front, .back 
      backface-visibility: hidden;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%
      border-radius 10px 
      padding-bottom: 20px

    /* front pane, placed above back */
    .front 
      z-index: 2;
      /* for firefox 31 */
      transform: rotateX(0deg);
  

    /* back, initially hidden pane */
    .back 
      transform: rotateX(180deg);      


      
//hack fom macOS

@media (min-resolution: 192dpi) and (min-width: 1300px), (min-resolution: 2dppx) and (min-width: 1300px), (-webkit-min-device-pixel-ratio: 2) and (min-width: 1300px)
  .flip-container
    perspective 0
    transform-style inherit  