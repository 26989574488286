.unread_counter
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -5px;
  right: -5px;
  width: 20px;
  height: 20px;
  font-size: 12px;
  border-radius: 99px;
  background: #39b54a;
  color: #fff;
  &__
    &counter
      line-height: 1
      margin-top: -4px 

.all-count,
.unread-count
  w: auto
  h: 16px
  d: inline-flex
  jc: center 
  ai: center
  fw: 700
  color: white
  min-w: 16px
  br-radius: 25px

.unread-count
  bg: #39b54a
  mr: 5px

.all-count
  bg: #129fdd
  ml: 5px