.heading
  d flex
  f-dir column
  lh 17px
  text-align left
  // max-w 111px
  word-wrap  break-word
  &--quantity
    color: $c.blue--dark
    fs 14px
    fw 700
    ta left
    mb 5px
    mr 30px
    +media(from('sm'))
      fs 16px
    +media(from('md'))
      mr 15px
  &--flex
    ai: flex-start
  &__text
    d inline-block
    &--normal
      fs 12px
      fw 400
    &--bold
      color: $c.blue--dark
      fs 14px
      fw 700
      ta left