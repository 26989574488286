.ccsingle svg 
  width: 100%
  max-height: 60px

.creditcard svg.cardfront,
.creditcard svg#cardback 
  width: 100%
  -webkit-box-shadow: 1px 5px 6px 0px #000
  box-shadow: 1px 5px 6px 0px #000
  border-radius: 22px
.cardfront
  width 100px


.generatecard 
  cursor: pointer
  float: right
  font-size: 12px
  color: #fff
  padding: 2px 4px
  background-color: #909090
  border-radius: 4px
  cursor: pointer
  float: right

.creditcard .lightcolor,
.creditcard .darkcolor 
  -webkit-transition: fill 0.5s
  transition: fill 0.5s

.creditcard 
  width: 100%
  max-width: 400px
  position: relative
  cursor: pointer

.creditcard .lightblue 
  fill: #03a9f4

.creditcard .lightbluedark 
  fill: #0288d1

.creditcard .red 
  fill: #ef5350

.creditcard .reddark 
  fill: #d32f2f

.creditcard .purple 
  fill: #ab47bc

.creditcard .purpledark 
  fill: #7b1fa2

.creditcard .cyan 
  fill: #26c6da

.creditcard .cyandark 
  fill: #0097a7

.creditcard .green 
  fill: #66bb6a

.creditcard .greendark 
  fill: #388e3c

.creditcard .lime 
  fill: #d4e157

.creditcard .limedark 
  fill: #afb42b

.creditcard .yellow 
  fill: #ffeb3b

.creditcard .yellowdark 
  fill: #f9a825

.creditcard .orange 
  fill: #ff9800

.creditcard .orangedark 
  fill: #ef6c00

.creditcard .grey 
  fill: #bdbdbd

.creditcard .greydark 
  fill: #616161

.creditcard .front 
  transform: rotateY(0deg)
  position: absolute
  width: 100%
  max-width: 400px
  transform-style: preserve-3d
  transition: 0.6s
  backface-visibility: hidden
  color: #47525d

.creditcard .back 
  d: block
  transform: rotateY(180deg)
  position: absolute
  width: 100%
  max-width: 400px
  transform-style: preserve-3d
  transition: 0.6s
  backface-visibility: hidden
  color: #47525d

.creditcard.flipped 
  transform: rotateY(0deg)
  transition: 0.6s
  transform-style: preserve-3d

.creditcard.flipped .front 
  transform: rotateY(-180deg)

.creditcard.flipped .back 
  transform: rotateY(0deg)

#svgname 
  text-transform: uppercase

.cardfront .st2 
  fill: #fff

.cardfront .st3 
  font-family: 'Roboto', monospace
  font-weight: 600

.cardfront .st4 
  font-size: 54.7817px

.cardfront .st5 
  font-family: 'Roboto', monospace
  font-weight: 400

.cardfront .st6 
  font-size: 33.1112px

.cardfront .st7 
  opacity: 0.6
  fill: #fff

.cardfront .st8 
  font-size: 24px

.cardfront .st9 
  font-size: 36.5498px

.cardfront .st10 
  font-family: 'Roboto', monospace
  font-weight: 300

.cardfront .st11 
  font-size: 16.1716px

.cardfront .st12 
  fill: #4c4c4c

#cardback .st0 
  fill: none
  stroke: #0f0f0f
  stroke-miterlimit: 10

#cardback .st2 
  fill: #111

#cardback .st3 
  fill: #f2f2f2

#cardback .st4 
  fill: #d8d2db

#cardback .st5 
  fill: #c4c4c4

#cardback .st6 
  font-family: 'Roboto', monospace
  font-weight: 400

#cardback .st7 
  font-size: 27px

#cardback .st8 
  opacity: 0.6

#cardback .st9 
  fill: #fff

#cardback .st10 
  font-size: 24px

#cardback .st11 
  fill: #eaeaea

#cardback .st12 
  font-family: 'Roboto', cursive

#cardback .st13 
  font-size: 37.769px

