
.preload
  pos fixed
  top 0
  left 0
  w 100%
  h 100%
  bg white 
  z-index: 100
  &--with-opacity
    bg rgba(white, 0.7)
  .loader 
    transform: translateX(-50%)translateY(-50%)
    pos absolute
    top 50%
    left 50% 
    d flex
    ai center 
    jc center 
    f-dir column
    .ball
      width: 20px
      height: 20px
      background-color: $c.red--dark
      border-radius: 50%
      display: inline-block
      animation: motion 3s infinite linear
    
    p
      color: $c.red--dark
      margin-top: 5px
      font-family: 'Titillium Web', sans-serif
      letter-spacing: 3px
      font-size: 10px
    
@keyframes motion
  0%
    transform: translateX(0) scale(1)
  25%
    transform: translateX(-50px) scale(0.3)
  50%
    transform: translateX(0) scale(1)
  75%
    transform: translateX(50px) scale(0.3)
  100%
    transform: translateX(0) scale(1)