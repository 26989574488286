@require '../stem-stylus-extensions'

@require 'definitions'
// @require 'base'

//temporarily bc can`t import 'base', when 'mantis query' is used

border-box()


html,
body
  h 100%
  ma 0


body
  bg red
  font-family Montserrat, Arial, Helvetica
  fs: $Font.size.body
  fh: $Font.height.body


@require 'project/index'
