
.form-loader
  &:after
    pos absolute
    top 0
    right 0
    bottom 0
    left 0
    z-index 10
    d block

    bg-color #fff9
    bg-image url('/static/img/load.svg')
    bg-position center center
    bg-repeat no-repeat
    content ''
    user-select none
.lms-stripe-view
  &__
    &content
      position relative
    &button
      mt: 12px
[id^='error-message_']
  color: red
  font-size: 14px
  fw 600


[id^='payment-element-messaging_']
  mt: 20px
  &.is-active
    border: 2px solid #129fdd !important;
    pa: 10px !important
    border-radius: 3px !important