@b tabs
  d block
  pa 0
  ma 2sp 0
  // mb 4sp

  br: 1px solid $c.relief--dark
  br-radius 3px

  @e
    &list
      br-top-right-radius 3px
      br-top-left-radius 3px
      list-style none
      clear fix
      ma 0
      pa 0
      d flex

      bg: $c.asphalt
      br-bottom: 1px solid $c.asphalt--dark

    &toggler
      d block
      float left

      &:first-child
        @bn
          @e
            &link
              br-top-left-radius 3px

    &link
      d block
      pv 2.5sp
      ph 2sp
      ta center
      fs 1ty
      fw normal
      fd none
      ft uppercase
      color #fff
      mb -1px
      br-bottom: 1px solid $c.asphalt--dark
      br-right: 1px solid $c.asphalt--dark
      min-h 100%
      min-h calc(100% + 1px)
      d flex
      align-items center

      @s
        &active
          bg #fff
          color: $c.asphalt
          br-bottom-color #fff

      &.has-error
        br-bottom-color: $c.red

        bg-image: linear-gradient(bottom, rgba($c.red, .7) 5%, rgba($c.red, 0) 5%)

    &content
      ma 0
      pa 0
      list-style none

    &pane
      pa 3sp 1sp
      pa--md 3sp
      &.is_clear
        pa 0 !important
      &.is-unactive
        d: none
      &.is-active
        d: block


  @media (max-width: 768px)
    &__
      &list
        d block
      &toggler
        d block
        // w 100%
        float none

    .form
      &__
        &row
          d block
