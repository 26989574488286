@b h-employee
  fd none

  @m
    &driver
    &self-driven
      icon dot

      &:before
        fs .6em
        mr .8em
        ml 0 - @mr - 1em
        mt 0 - .3em
        va middle

    &self-driven
      &:before
        color: $c.orange


@b h-badge
  d inline-block
  w 14px
  h 14px
  fh @h * 1.1
  fs @h * .6
  br-radius 999px
  color #fff
  bg: $c.blue
  va middle
  ta center

  @m
    &iconed
      va middle

    &success
      bg: $c.green

    &success&iconed
      icon check

    &error
      bg: $c.red

    &error&iconed
      icon minus

    &intermediate
      bg: $c.orange

    &intermediate&iconed
      icon question


.h-text
  &--
    &success
      color: $c.green

    &error
      color: $c.red

    &warning
      color: $c.orange


@b h-label
  d inline-block
  br-radius 999px
  ph 1sp
  color #fff
  bg: $c.blue
  va middle
  ta center

  @m
    &iconed
      va middle

    &success
      bg: $c.green

    &success&iconed
      icon check

    &error
      bg: $c.red

    &error&iconed
      icon minus

    &warning
      bg: $c.orange

    &warning&iconed
      icon minus

    &intermediate
      bg: $c.orange

    &intermediate&iconed
      icon question

    &fat
      pa .25sp 2sp

    &square
      br-radius 2px

    &status
      $statuses = ( \
        ('IN_PROCESS'    0 $c.violet) \
        ('RECEIVED'      1 $c.cyan) \
        ('NOT_CONFIRMED' 2 $c.yellow) \
        ('CONFIRMED'     3 $c.blue) \
        ('IN_WORK'       4 $c.orange) \
        ('DONE'          5 $c.green) \
        ('CANCELED'      6 $c.red) \
        ('NEW'           7 $c.lavender) \
      )

      &-
        for $status in $statuses
          &{$status[1]}
            bg $status[2]

            if contrast(#fff, $status[2]).ratio >= 2
              color #fff
            else
              color #000


@b h-align
  @m
    &center
      text-align center

      @m
        &md
          text-align--md center

        &lg
          text-align--lg center

    &left
      text-align left

      @m
        &md
          text-align--md left

        &lg
          text-align--lg left

    &right
      text-align right

      @m
        &md
          text-align--md right

        &lg
          text-align--lg right


.h-display
  &--
    &inline-block
      d inline-block


.is
  &-
    &hidden
      d none

.is-hidden-xl      
  +media(from('xl'))
    d none
.is-visible-xl      
  +media(to('lg'))
    d none


.collapsable-table-row
  +media(to('lg'))
    d flex
    jc space-between
    flex-wrap wrap

.can-collabse-mobile-table
  +media(to('lg'))
    // bg: $c.blue
    w: 50%
    // color: white
    fw 700
    br-bottom: 1px solid $c.relief--dark
    &::before
      d block
      content: attr(data-title)
      font-weight: 300
      fs 9px
      text-transform: uppercase
.is-visible-collapsable-col
  +media(to('lg'))
    d block !important
    w: 50%
    jc space-between
    fw 700
    flex-wrap wrap
    d block
    &::before
      content: attr(data-title);
      d block
      font-weight: 300
      fs 9px;
      text-transform: uppercase;

.hidden-head-table
  +media(to('lg'))
    d block 
    w 100%
    
    .list-table
      &__head
        d none
      &__body
        d block

.flex-center
  d: flex
  ai: center
  jc: center