@font-face
	font-family: "svgfont"
	src: url('./../fonts/svgfont.eot')
	src: url('./../fonts/svgfont.eot?#iefix') format('embedded-opentype'), 
			 url('./../fonts/svgfont.woff') format('woff'),
			 url('./../fonts/svgfont.woff2') format('woff2'),
			 url('./../fonts/svgfont.ttf') format('truetype')
	font-weight: normal
	font-style: normal

$icon-flate= "\EA01"
$icon-free= "\EA02"
$icon-video= "\EA03"
$icon-booking= "\EA04"
$icon-dropdown= "\EA05"
$icon-datepicker= "\EA06"
$icon-error= "\EA07"
$icon-prev= "\EA08"
$icon-next= "\EA09"
$icon-close= "\EA0A"
$icon-plus= "\EA0B"
$icon-minus= "\EA0C"
$icon-wallet= "\EA0D"
$icon-substract= "\EA10"
$icon-choice= "\EA11"
$icon-dashboard= "\EA12"
$icon-speedometer= "\EA13"
$icon-briefcase= "\EA14"
$icon-hand= "\EA16"
$icon-loss= "\EA17"
$icon-monitor= "\EA18"
$icon-note= "\EA19"
$icon-micro= "\EA20"
$icon-calculator= "\EA21"
$icon-messages= "\EA22"
$icon-person= "\EA23"


$icon
	font-family: "svgfont"
	font-style: normal
	font-weight: normal
	text-rendering: auto
	speak: none
	line-height: 1
	-webkit-font-smoothing: antialiased
	-moz-osx-font-smoothing: grayscale

.icon, [class^="icon-"], [class*=" icon-"]
	@extend $icon
	display: inline-block

.icon-flate:before
	content: $icon-flate
.icon-free:before
	content: $icon-free
.icon-video:before
	content: $icon-video
.icon-booking:before
	content: $icon-booking
.icon-dropdown:before
	content: $icon-dropdown
.icon-datepicker:before
	content: $icon-datepicker
.icon-error:before
	content: $icon-error
.icon-prev:before
	content: $icon-prev
.icon-next:before
	content: $icon-next
.icon-close:before
	content: $icon-close
.icon-plus:before
	content: $icon-plus
.icon-minus:before
	content: $icon-minus
.icon-wallet:before
	content: $icon-wallet
.icon-substract:before
	content: $icon-substract
.icon-choice:before
	content: $icon-choice
.icon-dashboard:before
	content: $icon-dashboard
.icon-speedometer:before
	content: $icon-speedometer
.icon-briefcase:before
	content: $icon-briefcase
.icon-hand:before
	content: $icon-hand
.icon-loss:before
	content: $icon-loss
.icon-monitor:before
	content: $icon-monitor
.icon-note:before
	content: $icon-note
.icon-micro:before
	content: $icon-micro
.icon-calculator:before
	content: $icon-calculator
.icon-messages:before
	content: $icon-messages
.icon-person:before
	content: $icon-person
