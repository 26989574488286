@b form

  @e
    &row
      $offsets = ( \
        (1 3sp) \
        (2 4sp) \
        (3 5sp) \
      )

      d flex
      mh 0 - 2sp
      ai: flex-start

      @m
        for $o in $offsets
          &o-{$o[0]}
            mb $o[1]

        for $i in 1..12
          &s-{$i}
            @bn
              @e
                &cell
                  flex-grow 1
                  w 100%
                  min-w @w
                  w--md ($i * 2) * (100% / 12)
                  min-w--md ($i * 2) * (100% / 12)
                  w--lg $i * (100% / 12)
                  min-w--lg $i * (100% / 12)
        &align-
          &end
            align-items flex-end
        &s-0
          @bn
            @e
              &cell
                @media (max-width: $ResponsiveBreakpoints.md)
                  flex-grow 1
                  w 100%
                  min-w @w
        &wrap
          flex-wrap wrap
        &responsive
          flex-wrap wrap
          justify-content space-between
          +media(from('xl'))
            ai: flex-end
        &underlined
          br-bottom: 2px solid $c.relief--dark

    &hidden
      .formset__button
        d none
    &cell
      ph 2sp
      flex-basis 10px

      & .field-wrapper
        mb 2sp

      @m
        for $i in 0..13
          $i -= 1
          &s-{$i}
            flex-grow $i
          &s-{$i}--md
            flex-grow--md $i
          &s-{$i}--lg
            flex-grow--lg $i
        &s-100
          w: 100%
          flex-basis: auto

        &full
          +media(to('lg'))
            width 100%
            flex-basis: initial
        
        &half
          +media(to('lg'))
            width 50%
            flex-basis: initial
        &middle
          va middle

        &bottom
          va bottom
          display flex
          align-items flex-end
          justify-content center


    &body
      mb 4sp

    &footer
      d flex
      mh 0 - 2sp
      +media(to('lg'))
        flex-wrap wrap
        ai center
        f-dir row
        flex-wrap: wrap
        jc center

    &action
      pa 2sp

      &:last-child
        flex-grow 1
        ta right
      +media(between('sm', 'lg'))
        flex-wrap wrap
        ai center
        w auto
        f-dir row
        d flex
        jc space-between
        .button
          w auto
          d flex
          ai center
          jc center
          mb 10px
          mr 10px
      +media(to('sm'))
        flex-wrap wrap
        ai center
        w 100%
        // f-dir column
        d flex
        jc space-between
        flex-wrap wrap
        .button
          w 100%
          d flex
          ai center
          jc center
          mb 10px
    &title
      fw 900
      fs 30px
      ft uppercase
      mb 2ty
      pa 3sp
      mh -3sp
      mb 3sp
      mt -1sp

      // bg: $c.relief
      br-bottom: 1px solid $c.relief--dark

      @m
        &in-body
          ph 2sp
          mh -2sp
          mb 2sp

    &section
      pa 3sp
      mh -3sp
      mb 4sp

      br-radius $border-radius
      // br: 1px solid $c.relief--dark

      bg: $c.relief

    &section > &body
      mb 0

    &section &title
      mt -3sp

    &delimiter
      mv 2sp

      br-bottom: 1px solid $c.relief--dark
  @m
    &modal
      pa 15px

@b formset
  mb 2sp
  &--
    &load
    &unload
      .list-table__head .list-table__cell
      .field-input
        font-size: 10px
    &load
      .list-table__cell--f-latitude, 
      .list-table__cell--f-longitude,
      .list-table-head__cell--f-latitude, 
      .list-table-head__cell--f-longitude
        d: none
    &day_salaries
      .formset__
        &button
          d none

      .list-table

        @media (max-width: 960px)
          &__
            &head
              d none

            &row
              d block
              mb 2sp
              // border-bottom: $c.blue solid 1px

            &cell
              d block
              w 100% !important

              &:before
                bg transparent
                ma 0

              &--
                &index
                &last
                  d none

form.is-loading
  & button
  & input[type="submit"]
  & input[type="button"]
    user-select none
    pointer-events none
    filter grayscale()
    opacity .5


.flatpickr-time input
  width 68px

.flatpickr-days
  .dayContainer
    display flex
    flex-wrap wrap

.input-group__item--xl-66
  w 100%

.klarna-btn
  bg: rgb(255, 179, 199);
  d: flex
  ai: center
  jc: center
  pa: 10px 20px
  br-radius: 3px
  margin-top: 10px
  h: 40px
  cursor: pointer
  &__
    &icon
      width auto
      height 40px
      d: block
    &text
      color: #fff
      font-size: 14px
      font-weight: 700
      pa: 0
      ma: 0
      margin-left: 10px
