#nprogress 
  pointer-events none


#nprogress .bar 
  background #29d
  position fixed
  z-index 1031
  top 0
  left 0
  width 100%
  height 2px

.localloader
  pos absolute 
  d flex
  ai center 
  jc center
  w 100%
  h 100%
  bg rgba(white, 0.7)
  z-index 5
  top 0
  left 0
  .spinner 
    position absolute !important
    top 50%
    w 18px
    left 50%
    transform translate(-50%, -50%)
#nprogress .peg 
  display block
  position absolute
  right 0
  width 100px
  height 100%
  box-shadow 0 0 10px #29d, 0 0 5px #29d
  opacity 1
  transform rotate(3deg) translate(0px, -4px)


.spinner 
  display block
  position fixed
  z-index 1031
  top 15px
  right 15px


.spinner-icon 
  width 18px
  height 18px
  box-sizing border-box
  border solid 2px transparent
  border-top-color #29d
  border-left-color #29d
  border-radius 50%
  animation nprogress-spinner 0.5s linear infinite


.nprogress-custom-parent 
  overflow hidden
  position relative


.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar 
  position absolute

@keyframes nprogress-spinner 
  0% 
    transform rotate(0deg)
  

  100% 
    transform rotate(360deg)
  
