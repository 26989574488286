@b miscellaneous
  &
    @extends $view

  @e
    $offset = 3sp

    &list
      mv 4sp
      mh 0 - $offset
      pa 0

      list-style none
      d flex
      flex-wrap wrap
      align-items center
      justify-content center

    &item
      pa $offset
      w 25%
      min-w 250px
      flex-grow 1

    &link
      d block
      d flex
      pa 4sp
      h 6.3sp
      align-items center
      justify-content center

      br: 1px solid transparent

      fs 4ty
      ft uppercase
      fd none
      color inherit

      bg: $c.relief--dark

      &:hover
        br-color: $c.blue

      & .icon
        fs 1.3em
        float left
        mr 2sp
